/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { formatter, getItemsAmounts, paymentMethodInfo } from '../functions/general'
import oxxo from '../assets/images/oxxo.png'
import { Alert, Button, Col, Row, Space, Spin, Table, Tag, Typography, Image, Divider, Card } from 'antd'
import { LogoHeader } from './GenerateInvoice'
import { useState } from 'react'
import StripeContainer from '../app/payments/StripeContainer'
import { IoChevronBackOutline } from 'react-icons/io5'
import BankContainer, { InfoData } from '../app/payments/BankContainer'
import moment from 'moment'
import PaymentWaitingForReview from '../app/payments/PaymentWaitingForReview'
import PaymentCompleted from './PaymentCompleted'
import { useSelector } from 'react-redux'
import PoweByGig from './PoweByGig'
import PrivacyBanner from './NoticeOfPrivacy'
import TryGigstackCard from './TryGigstackCard'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { set } from 'react-ga'
import PowerByGigPrivacy from './PowerdByGigPrivacy'
import { DownloadSimple } from '@phosphor-icons/react'
import { Bank, CreditCard } from '@phosphor-icons/react'
import TestModeBanner from '../components/TestmodeBanner'

const PaymentMethods = ({ payment, token }) => {
    // const formatter = new Intl.NumberFormat('es-MX', {
    //     style: 'currency',
    //     currency: 'MXN',
    // });
    const { currency, items, id, timestamp, paidIn, livemode } = payment
    const { paymentInReview, waittingForOxxo, paymentCompletedContext, confirmedMethod } = useSelector((state) => state.payments)

    const { logo, alias, primaryColor } = payment.brand
    const currencyFormat = Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency,
    })

    const [paymentMethodSelected, setPaymentMethodSelected] = useState(paidIn ?? null)
    const [loading, setLoading] = useState(null)

    const hasBankDiscount = getItemsAmounts({ items, useDiscount: true }).feeAdded > 0 || items[0]?.master

    let amounts =
        hasBankDiscount && paymentMethodSelected === 'bank'
            ? getItemsAmounts({
                  items,
                  useDiscount: paymentMethodSelected === 'bank',
              })
            : getItemsAmounts({
                  items,
                  useDiscount: paymentMethodSelected === 'bank',
              })
    let subtotal = amounts?.subtotalString
    let taxes = amounts?.taxesString
    let retentions = amounts?.retentionsString
    let discount = formatter.format((amounts?.masterDiscount ?? 0) + (amounts?.feeAdded ?? 0))
    let total =
        hasBankDiscount && paymentMethodSelected === 'bank'
            ? formatter.format(amounts?.total - amounts?.feeAdded)
            : amounts?.totalString

    const initial = {
        opacity: 0,
        y: 50,
    }

    const animate = {
        opacity: 1,
        y: 0,
    }

    const transition = {
        duration: 0.8,
    }

    const PaymentData = () => {
        return (
            <div
                className="d-flex flex-column"
                style={{
                    padding: '10px',
                    width: '100%',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    marginBottom: '20px',
                    // boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.04)',
                    // backgroundImage: `url(${bckground})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
                <Typography.Text>Información del pago </Typography.Text>
                <Row justify="space-between" style={{ marginBottom: '10px', marginTop: '5px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>ID</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>{id}</Typography.Text>
                </Row>
                <Row justify="space-between" style={{ marginBottom: '10px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        Fecha de solicitud
                    </Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                        {moment(timestamp).format('DD/MM/YYYY HH:mm')}
                    </Typography.Text>
                </Row>
            </div>
        )
    }

    const PaymentItems = ({ primaryColor }) => {
        return (
            <div
                className="d-flex flex-column"
                style={{
                    width: '100%',
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '10px',
                }}
            >
                <Typography.Text>Total a pagar</Typography.Text>
                <Typography.Text style={{ fontSize: '30px', fontWeight: 900, marginLeft: '10px' }}>
                    {total}{' '}
                    <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{(currency ?? 'MXN').toUpperCase()}</span>{' '}
                </Typography.Text>
                <Table
                    dataSource={items}
                    showHeader={false}
                    pagination={{
                        hideOnSinglePage: true,
                    }}
                    rowKey={(record) => record.id}
                    columns={[
                        {
                            title: 'Concepto',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text, record) => {
                                return <Typography.Text style={{ fontSize: '12px' }}>{text}</Typography.Text>
                            },
                        },
                        {
                            title: 'Precio',
                            dataIndex: 'total',
                            key: 'total',
                            render: (text, record) => {
                                return (
                                    <Typography.Text style={{ fontSize: '12px' }}>
                                        <span style={{ fontSize: '12px', color: '#cecece' }}>
                                            ({record?.quantity}x)
                                        </span>{' '}
                                        {formatter.format(text)} {(currency ?? 'MXN').toUpperCase()}
                                    </Typography.Text>
                                )
                            },
                        },
                    ]}
                />

                {!payment?.hideTaxes && (
                    <>
                        <Row
                            justify="space-between"
                            style={{
                                marginTop: '10px',
                                marginLeft: '5px',
                                marginRight: '5px',
                            }}
                        >
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Subtotal</Typography.Text>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                {subtotal}
                            </Typography.Text>
                        </Row>
                        <Row
                            justify="space-between"
                            style={{
                                marginTop: '5px',
                                marginLeft: '5px',
                                marginRight: '5px',
                            }}
                        >
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>IVA</Typography.Text>
                            <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                {taxes}
                            </Typography.Text>
                        </Row>
                        {getItemsAmounts({ items, useDiscount: true })?.retentions > 0 && (
                            <Row
                                justify="space-between"
                                style={{
                                    marginTop: '5px',
                                    marginLeft: '5px',
                                    marginRight: '5px',
                                }}
                            >
                                <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                    Retenciones
                                </Typography.Text>
                                <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                                    {retentions}
                                </Typography.Text>
                            </Row>
                        )}
                    </>
                )}
                {hasBankDiscount && paymentMethodSelected === 'bank' && (
                    <Row justify="space-between" style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
                        <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Descuento 🎁</Typography.Text>
                        <Typography.Text
                            style={{
                                fontSize: '12px',
                                fontWeight: 'normal',
                                fontStyle: 'italic',
                            }}
                            type="secondary"
                        >
                            -{discount}
                        </Typography.Text>
                    </Row>
                )}
                <Row justify="space-between" style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px' }}>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>Total</Typography.Text>
                    <Typography.Text style={{ fontSize: '12px', fontWeight: 'normal' }} type="secondary">
                        {total} {(currency ?? 'MXN').toUpperCase()}
                    </Typography.Text>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    {payment?.shortUrlInvoice && (
                        <Button
                            style={{
                                backgroundColor: primaryColor,
                                marginBottom: '20px',
                                marginTop: '20px',
                            }}
                            type="primary"
                            size="small"
                            onClick={() => {
                                window.open(payment?.shortUrlInvoice, '_blank')
                            }}
                        >
                            Ver factura relacionada a este pago
                        </Button>
                    )}
                </Row>

                <PoweByGig hidePBG={payment?.user?.brand.hidePBG} />
            </div>
        )
    }

    const BackToPaymentForms = () => {
        return (
            !paymentCompletedContext && (
                <Row
                    align="middle"
                    style={{ marginBottom: '20px' }}
                    className="clickable"
                    onClick={() => {
                        if (!paymentInReview && !waittingForOxxo) setPaymentMethodSelected(null)
                    }}
                >
                    {!paymentInReview && !waittingForOxxo && (
                        <>
                            <IoChevronBackOutline
                                style={{
                                    fontSize: '18px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                    cursor: 'pointer',
                                }}
                            />
                            <Typography.Text
                                level={5}
                                style={{ margin: 0, marginLeft: '10px', fontSize: '12px' }}
                                type="secondary"
                            >
                                Seleccionar forma de pago
                            </Typography.Text>
                        </>
                    )}
                </Row>
            )
        )
    }

    const PayWithCard = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    borderRadius: '10px',
                    marginTop: '25px',
                }}
            >
                {/* <BackToPaymentForms /> */}
                <StripeContainer
                    stripeAccount={payment.user.stripeAccountID}
                    succeededPayment={(p) => {}}
                    paymentData={payment}
                    livemode={payment.livemode}
                />
            </div>
        )
    }

    const PayWithOxxo = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    borderRadius: '10px',
                    marginTop: '25px',
                }}
            >
                {/* <BackToPaymentForms /> */}
                <StripeContainer
                    stripeAccount={payment.user.stripeAccountID}
                    paymentData={payment}
                    oxxo={true}
                    livemode={payment.livemode}
                    succeededPayment={(p) => {}}
                />
            </div>
        )
    }

    const PayWithBank = () => {
        return (
            <div className="d-flex flex-column">
                {/* <BackToPaymentForms /> */}
                <BankContainer
                    paymentData={payment}
                    bank={payment?.bank}
                    primaryColor={payment?.brand?.primaryColor}
                    token={token}
                />
            </div>
        )
    }

    const PayWithCustomerBalance = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: 'white',
                    width: '100%',
                    borderRadius: '10px',
                    marginTop: '25px',
                }}
            >
                {/* <BackToPaymentForms /> */}
                <StripeContainer
                    stripeAccount={payment.user.stripeAccountID}
                    paymentData={payment}
                    oxxo={false}
                    customerBalance={true}
                    livemode={payment.livemode}
                    succeededPayment={(p) => {}}
                />
            </div>
        )
    }

    const RenderPaymentMethod = () => {
        switch (paymentMethodSelected) {
            case 'bank':
                if (payment?.payment_method_options?.customer_balance?.bank_transfer?.type === 'mx_bank_transfer') {
                    return <PayWithCustomerBalance />
                } else {
                    return <PayWithBank />
                }
            case 'customer_balance':
                return <PayWithCustomerBalance />
            case 'card':
                return <PayWithCard />
            case 'oxxo':
                return <PayWithOxxo />
            default:
                return <></>
        }
    }

    const AvailablePaymentMethods = () => {

        const { paymentCompletedContext } = useSelector((state) => state.payments)

        if(paymentCompletedContext) return <></>
        
        return (
            <>
                <Typography.Text className="h5-bold neutral-1">Selecciona un método de pago</Typography.Text>
                <Row gutter={8} style={{ marginTop: '15px' }}>
                    {payment?.custom_method_types?.map((paymentMethod, index) => (
                        <Col
                            className={`rounded-md ${
                                confirmedMethod && confirmedMethod !== paymentMethod.id ? 'btn-disabled' : 'neutral-1'
                            }`}
                            onClick={() => setPaymentMethodSelected(paymentMethod.id)}
                            key={payment?.custom_method_types.id}
                            span={24 / payment?.custom_method_types.length}
                        >
                            <div
                                className="rounded-md"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    gap: '10px',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: '90px',
                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                    border:
                                        paymentMethodSelected === paymentMethod.id
                                            ? '2px solid rgba(15, 23, 42, 1)'
                                            : '2px solid rgba(244, 244, 245, 1)',
                                }}
                            >
                                {paymentMethod.id === 'oxxo' && <img src={oxxo} width={35} alt="" />}
                                {paymentMethod.id === 'card' && <CreditCard style={{ fontSize: ' 16px' }} />}
                                {(paymentMethod.id === 'bank' || paymentMethod.id === 'customer_balance') && (
                                    <Bank style={{ fontSize: '16px' }} />
                                )}
                                <Typography.Text style={{ textAlign: 'center' }} className="p-base-regular neutral-1">
                                    {paymentMethodInfo(paymentMethod.id).text}
                                </Typography.Text>
                            </div>
                        </Col>
                    ))}
                </Row>
                {/* <Row>
          <Space direction="horizontal" style={{ width: '100%' }}>
            {payment?.custom_method_types?.map((paymentMethod, index) => {
              return (
                <motion.div
                  onClick={() => {
                    setPaymentMethodSelected(paymentMethod.id)
                  }}
                  initial={initial}
                  animate={animate}
                  transition={transition}
                  className="paymentMethod"
                  style={{ width: '100%', borderRadius: '10px', backgroundColor: '#fafafa' }}
                  key={paymentMethod.id}>
                  <Row
                    justify="space-between"
                    className="bg-white"
                    style={{ marginTop: '5px', padding: '15px', border: '2px rgba(244, 244, 245, 1)' }}>
                    <Row align="middle" style={{ border: '2px rgba(244, 244, 245, 1)' }}>
                      <img
                        src={paymentMethodInfo(paymentMethod.id).image}
                        width={25}
                        height={25}
                        alt=""
                        style={{ marginRight: '10px' }}
                      />
                      <Typography.Text style={{ fontSize: '12px', fontWeight: 'bold' }}>
                        {paymentMethodInfo(paymentMethod.id).text}
                      </Typography.Text>
                    </Row>
                    {/* {hasBankDiscount && paymentMethodInfo(paymentMethod.id).useDiscount && <Tag style={{
                                    border: '1px solid #8666FF',
                                }}>{getItemsAmounts({ items, useDiscount: true }).feeAddedString} de descuento</Tag>}}
                  </Row>
                </motion.div>
              )
            })} 
          </Space>
        </Row>
        */}
            </>
        )
    }

    const OxxoOnHold = () => {
        return (
            <div
                style={{
                    padding: '20px 20px',
                    border: '1px solid #f7f7f7',
                    borderRadius: '10px',
                }}
                className="d-flex flex-column"
            >
                <img
                    src="https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg"
                    alt="oxxo"
                    height={50}
                    width={50}
                />
                <Typography.Title level={5} style={{ margin: 0 }}>
                    En espera de pago
                </Typography.Title>
                <Typography.Text>
                    Para obtener la línea de captura de nuevo por favor haz click en el siguiente botón
                </Typography.Text>
                <Button
                    type="dashed"
                    onClick={() => {
                        window.open(payment?.next_action?.oxxo_display_details?.hosted_voucher_url, '_blank')
                    }}
                    style={{
                        borderColor: payment?.brand?.primaryColor,
                        marginTop: '25px',
                    }}
                >
                    Ver línea de captura
                </Button>
            </div>
        )
    }

    const CustomerBalanceOnHold = () => {
        const clabe = payment?.next_action?.display_bank_transfer_instructions?.financial_addresses?.[0]?.spei?.clabe
        const bankName =
            payment?.next_action?.display_bank_transfer_instructions?.financial_addresses?.[0]?.spei?.bank_name
        const reference = payment?.next_action?.display_bank_transfer_instructions?.reference

        return (
            <div
                style={{
                    padding: '20px 20px',
                    border: '1px solid #f7f7f7',
                    borderRadius: '10px',
                }}
                className="d-flex flex-column"
            >
                <img
                    src="https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png"
                    alt="oxxo"
                    height={50}
                    width={50}
                />
                <Typography.Title level={5} style={{ margin: 0 }}>
                    En espera de pago
                </Typography.Title>
                <Typography.Text>
                    Para completar el pago por favor realiza una transferencia SPEI usando la siguiente información:{' '}
                </Typography.Text>
                <div style={{ marginTop: '20px' }}>
                    <InfoData
                        title="Banco"
                        value={
                            <>
                                {bankName} <span>{}</span>
                            </>
                        }
                    />
                    <InfoData title="CLABE" value={clabe} />
                    <InfoData title="Referencia" value={reference} />
                </div>

                <Alert
                    message={
                        'Si ya realizaste el pago, no te preocupes, estamos procesando la transacción. Recibirás un correo cuando recibamos la transferencia'
                    }
                    type="warning"
                    showIcon
                    style={{ margin: '20px 0px 10px 0px' }}
                />

                {/* <Button type="dashed" onClick={() => {
                window.open(payment?.next_action?.oxxo_display_details?.hosted_voucher_url, '_blank')
            }} style={{ borderColor: payment?.brand?.primaryColor, marginTop: '25px' }}>Ver línea de captura</Button> */}
            </div>
        )
    }

    const ToRender = () => {
        if (payment.status === 'review_requested') {
            return <PaymentWaitingForReview brand={payment?.brand} />
        } else if (payment.status === 'requires_action') {
            if (payment?.next_action?.oxxo_display_details) {
                return <OxxoOnHold />
            } else if (
                payment?.next_action
                    ?.display_bank_transfer_instructions /*payment?.payment_method_options?.customer_balance?.bank_transfer?.type === 'mx_bank_transfer'*/
            ) {
                return <CustomerBalanceOnHold />
            } else {
                return (
                    <>
                        <AvailablePaymentMethods />
                        <RenderPaymentMethod />
                    </>
                )
            }
        } else {
            return payment.status !== 'refunded' && payment.status !== 'canceled' && paymentMethodSelected ? (
                <>
                    <AvailablePaymentMethods />
                    <RenderPaymentMethod />
                </>
            ) : (
                <>
                    <AvailablePaymentMethods />
                    <RenderPaymentMethod />
                </>
            )
        }
    }

    return (
        <>
            {!livemode && <TestModeBanner />}
            {payment.status === 'succeeded' ? (
                <PaymentCompleted brand={payment?.brand} payment={payment} />
            ) : (
                <Row
                    justify="center"
                    style={{
                        width: '100%',
                        minHeight: '100vh',
                        padding: 0,
                        margin: 0,
                        overflow: 'hidden',
                    }}
                >
                    <Col xs={24} lg={0}>
                        <div
                            className="d-flex flex-column"
                            style={{
                                justifyItem: 'start',
                                alignItems: 'start',
                                padding: '40px 5%',
                                width: '100%',
                            }}
                        >
                            <Row justify="center" style={{ width: '100%' }}>
                                <Image src={logo} alt={alias} width={100} preview={false} />
                            </Row>
                            <Typography.Text
                                style={{
                                    fontSize: '25px',
                                    marginTop: '10px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {total} {currency?.toUpperCase()}
                            </Typography.Text>
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy />
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: 'white', padding: '40px 5%' }}>
                        <ToRender />
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: '', padding: '30px 9%' }}>
                        <div className="d-flex flex-column" style={{ justifyItem: 'start', alignItems: 'start' }}>
                            <Image
                                src={logo}
                                alt={alias}
                                width={80}
                                preview={false}
                                // hide on xs
                                style={{
                                    display: window.innerWidth < 768 ? 'none' : 'block',
                                }}
                            />
                            <Typography.Text className="h6-bold neutral-1" style={{ marginTop: '22px' }}>
                                Total a pagar:
                            </Typography.Text>
                            <Typography.Text className="h3-bold neutral-1" style={{ marginTop: '10px' }}>
                                {currency?.toUpperCase()} {total}
                            </Typography.Text>
                            <Typography.Text className="p-base-regular neutral-1" style={{ marginTop: '10px' }}>
                                {currency?.toUpperCase()} {total}
                            </Typography.Text>
                            <div className="d-flex w-100" style={{ justifyContent: 'space-between' }}>
                                <Typography.Text className="p-base-regular neutral-1" style={{ marginTop: '10px' }}>
                                    Fecha de solicitud de pago:
                                </Typography.Text>
                                <Typography.Text className="p-base-regular neutral-1" style={{ marginTop: '10px' }}>
                                    {moment(timestamp).format('DD/MM/YYYY')}
                                </Typography.Text>
                            </div>
                            {payment?.shortUrlInvoice && (
                                <Row style={{ width: '100%' }}>
                                    <Button
                                        className="rounded-md btn-shadow neutral-1 w-100"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            border: '1px solid var(--neutral-4)',
                                            backgroundColor: 'var(--white)',
                                            marginBottom: '20px',
                                            marginTop: '20px',
                                        }}
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                            window.open(payment?.shortUrlInvoice, '_blank')
                                        }}
                                    >
                                        <span className="p-base-bold neutral-1">Descargar factura</span>
                                        <DownloadSimple className="neutral-1" style={{ marginLeft: '14px' }} />
                                    </Button>
                                </Row>
                            )}
                            <Typography.Text
                                className="h6-bold neutral-1"
                                style={{ marginTop: '22px', marginBottom: '15px' }}
                            >
                                Productos o servicios:
                            </Typography.Text>
                            {items.map((item, index) => (
                                <Row key={index} gutter={20} style={{ padding: 0, margin: 0, width: '100%' }}>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{ marginRight: '15px' }}
                                    >
                                        {item.quantity}
                                    </Typography.Text>
                                    <Typography.Text className="p-base-regular neutral-1" style={{ flex: 1 }}>
                                        {item.name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{
                                            textAlign: 'end',
                                            width: 'auto',
                                            marginLeft: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {currency?.toUpperCase()} {currencyFormat.format(item.total)}
                                    </Typography.Text>
                                </Row>
                            ))}
                            <Divider style={{ margin: '20px 0' }} />
                            <Row justify="end" style={{ width: '100%' }}>
                                <div className="d-flex flex-column">
                                    {!payment?.hideTaxes && (
                                        <Row justify="space-between">
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{
                                                    marginRight: '25px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'start',
                                                }}
                                            >
                                                Subtotal
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()} {currencyFormat.format(amounts?.subtotal)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    {!payment?.hideTaxes && (
                                        <Row justify="space-between" style={{ marginTop: '8px' }}>
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{
                                                    marginRight: '25px',
                                                    fontWeight: 'bold',
                                                    textAlign: 'start',
                                                }}
                                            >
                                                IVA
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()} {currencyFormat.format(amounts?.taxes)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    <Row justify="space-between" style={{ marginTop: '8px' }}>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{
                                                marginRight: '25px',
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Total
                                        </Typography.Text>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{ textAlign: 'end', fontWeight: 'bold' }}
                                        >
                                            {currency?.toUpperCase()} {currencyFormat.format(amounts?.total)}
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </Row>
                            <div style={{ margin: 'auto' }}>
                                <TryGigstackCard type="pago" hidePBG={payment.user.brand?.hidePBG} />
                            </div>
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy privacy={false} />
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default PaymentMethods
