import React from 'react'
import { Row, Col, Divider, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

const PrivacyBanner = () => {
    const { brand } = useSelector((state) => state.data)
    return (
        <div className="d-flex flex-column" style={{ marginTop: '10px', padding: '0px 25px' }}>
            <Row justify="center">
                <Col xs={23} style={{}}>
                    <Row justify="center" align="middle">
                        <img src={brand?.logo ?? ''} height="45px" alt={brand?.alias} />
                        <Divider type="vertical" />
                        <a
                            href={`https://gigstack.pro?utm_source=customerportal-${brand?.alias}&utm_medium=poweredby&utm_campaign=gportal-${brand?.alias}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                src={'https://pro-gigstack.s3.us-east-2.amazonaws.com/logos/Powered+by.png'}
                                height="50px"
                                alt="gistack.pro"
                            />
                        </a>
                    </Row>
                </Col>
                {/* <Col xs={24} style={{ marginTop: '10px' }}>
                                <a style={{ fontSize: '11px', color: "#333" }} target="_blank" rel="noreferrer" href='https://forms.gle/YUnEwF9YDYj2Qrdc9'>¿Nos ayudas a mejorar?</a>
                            </Col> */}
                <Col xs={24} style={{ marginTop: '10px' }}>
                    <Row justify="center">
                        <Typography.Text style={{ fontSize: '10px', textAlign: 'center' }} type="secondary">
                            {brand?.alias} ha automatizado su cobranza y facturación con gigstack.pro, conoce el{' '}
                            <a href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf">
                                aviso de privacidad
                            </a>
                            .
                        </Typography.Text>
                        {/* <Button type="link" style={{ fontSize: '11px', color: "#333" }} target="_blank" rel="noreferrer" href='https://gigstack.pro/privacy'>Visita nuestro aviso de privacidad</Button> */}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PrivacyBanner
