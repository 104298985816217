import { Typography } from 'antd'

export default function PowerByGigPrivacy({ privacy = true }) {
    return (
        <Typography.Text className="p-base-regular neutral-3" style={{ textAlign: 'center', marginTop: '20px' }}>
            Powered by <span className="russo-one neutral-1"> gigstack </span>
            {privacy && (
                <>
                    <br />
                    <Typography.Link
                        href="https://pro-gigstack.s3.us-east-2.amazonaws.com/legal/privacy.pdf"
                        target="_blank"
                        className="neutral-3-anchor p-xsmall-regular"
                    >
                        aviso de privacidad
                    </Typography.Link>
                </>
            )}
        </Typography.Text>
    )
}
