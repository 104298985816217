import { Card, Row, Typography } from "antd"
import { useSearchParams } from "react-router-dom"

export default function UntimedInvoiceMessage() {
    const [searchParams, setSearchParams] = useSearchParams()
    const receiptId = searchParams.get('id')

    return (
        <>
            <Card style={{ width: 300, marginTop: 16, border: 'none' }}>
                <Row justify="center">
                    <Typography.Title level={5} style={{ marginTop: '50px', padding: '0px' }}>
                        Recibo por facturar
                    </Typography.Title>
                </Row>
                <Row justify="center">
                    <Typography.Text style={{ marginTop: '10px', padding: '0px' }}>
                        {receiptId ?? ""}
                    </Typography.Text>
                </Row>
                <Row justify="center" style={{ marginTop: '10px' }}>
                    <Typography.Text type="secondary" className="" style={{ textAlign: 'center' }}>
                        Si deseas facturar este recibo, por favor ponte en contacto con gigstack aquí:
                    </Typography.Text>
                </Row>
                <Row justify="center" style={{ marginTop: '10px', marginBottom: '20px' }}>
                    <Typography.Text type="secondary" className="" style={{ textAlign: 'center' }}>
                        soporte@gigstack.io
                    </Typography.Text>
                </Row>
            </Card>
        </>
    )
}
