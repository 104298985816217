import { Row, Typography } from 'antd'

export default function TestModeBanner() {
    return (
        <div
            className="bg-blue-6 w-100"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                minHeight: '32px',
                zIndex: 2000,
            }}
        >
            <Row style={{ width: '100%' }}>
                <Typography.Text className="white p-small-bold" style={{ paddingLeft: '10px' }}>
                    Modo de prueba
                </Typography.Text>
                <Typography.Text className="white p-small-bold" style={{ margin: 'auto' }}>
                    Estás usando datos de prueba.
                </Typography.Text>
            </Row>
        </div>
    )
}
