export const countryAlphaCodes = [
    {
        code: 'AFG',
        name: 'Afghanistan',
        eu: false,
    },
    {
        code: 'ALA',
        name: 'Åland Islands',
        eu: false,
    },
    {
        code: 'ALB',
        name: 'Albania',
        eu: false,
    },
    {
        code: 'DZA',
        name: 'Algeria',
        eu: false,
    },
    {
        code: 'ASM',
        name: 'American Samoa',
        eu: false,
    },
    {
        code: 'AND',
        name: 'Andorra',
        eu: false,
    },
    {
        code: 'AGO',
        name: 'Angola',
        eu: false,
    },
    {
        code: 'AIA',
        name: 'Anguilla',
        eu: false,
    },
    {
        code: 'ATA',
        name: 'Antarctica',
        eu: false,
    },
    {
        code: 'ATG',
        name: 'Antigua and Barbuda',
        eu: false,
    },
    {
        code: 'ARG',
        name: 'Argentina',
        eu: false,
    },
    {
        code: 'ARM',
        name: 'Armenia',
        eu: false,
    },
    {
        code: 'ABW',
        name: 'Aruba',
        eu: false,
    },
    {
        code: 'AUS',
        name: 'Australia',
        eu: false,
    },
    {
        code: 'AUT',
        name: 'Austria',
        eu: true,
    },
    {
        code: 'AZE',
        name: 'Azerbaijan',
        eu: false,
    },
    {
        code: 'BHS',
        name: 'Bahamas',
        eu: false,
    },
    {
        code: 'BHR',
        name: 'Bahrain',
        eu: false,
    },
    {
        code: 'BGD',
        name: 'Bangladesh',
        eu: false,
    },
    {
        code: 'BRB',
        name: 'Barbados',
        eu: false,
    },
    {
        code: 'BLR',
        name: 'Belarus',
        eu: false,
    },
    {
        code: 'BEL',
        name: 'Belgium',
        eu: true,
    },
    {
        code: 'BLZ',
        name: 'Belize',
        eu: false,
    },
    {
        code: 'BEN',
        name: 'Benin',
        eu: false,
    },
    {
        code: 'BMU',
        name: 'Bermuda',
        eu: false,
    },
    {
        code: 'BTN',
        name: 'Bhutan',
        eu: false,
    },
    {
        code: 'BOL',
        name: 'Bolivia, Plurinational State of',
        eu: false,
    },
    {
        code: 'BES',
        name: 'Bonaire, Sint Eustatius and Saba',
        eu: false,
    },
    {
        code: 'BIH',
        name: 'Bosnia and Herzegovina',
        eu: false,
    },
    {
        code: 'BWA',
        name: 'Botswana',
        eu: false,
    },
    {
        code: 'BVT',
        name: 'Bouvet Island',
        eu: false,
    },
    {
        code: 'BRA',
        name: 'Brazil',
        eu: false,
    },
    {
        code: 'IOT',
        name: 'British Indian Ocean Territory',
        eu: false,
    },
    {
        code: 'BRN',
        name: 'Brunei Darussalam',
        eu: false,
    },
    {
        code: 'BGR',
        name: 'Bulgaria',
        eu: true,
    },
    {
        code: 'BFA',
        name: 'Burkina Faso',
        eu: false,
    },
    {
        code: 'BDI',
        name: 'Burundi',
        eu: false,
    },
    {
        code: 'KHM',
        name: 'Cambodia',
        eu: false,
    },
    {
        code: 'CMR',
        name: 'Cameroon',
        eu: false,
    },
    {
        code: 'CAN',
        name: 'Canada',
        eu: false,
    },
    {
        code: 'CPV',
        name: 'Cape Verde',
        eu: false,
    },
    {
        code: 'CYM',
        name: 'Cayman Islands',
        eu: false,
    },
    {
        code: 'CAF',
        name: 'Central African Republic',
        eu: false,
    },
    {
        code: 'TCD',
        name: 'Chad',
        eu: false,
    },
    {
        code: 'CHL',
        name: 'Chile',
        eu: false,
    },
    {
        code: 'CHN',
        name: 'China',
        eu: false,
    },
    {
        code: 'CXR',
        name: 'Christmas Island',
        eu: false,
    },
    {
        code: 'CCK',
        name: 'Cocos (Keeling) Islands',
        eu: false,
    },
    {
        code: 'COL',
        name: 'Colombia',
        eu: false,
    },
    {
        code: 'COM',
        name: 'Comoros',
        eu: false,
    },
    {
        code: 'COG',
        name: 'Congo',
        eu: false,
    },
    {
        code: 'COD',
        name: 'Congo, the Democratic Republic of the',
        eu: false,
    },
    {
        code: 'COK',
        name: 'Cook Islands',
        eu: false,
    },
    {
        code: 'CRI',
        name: 'Costa Rica',
        eu: false,
    },
    {
        code: 'CIV',
        name: "Côte d'Ivoire",
        eu: false,
    },
    {
        code: 'HRV',
        name: 'Croatia',
        eu: true,
    },
    {
        code: 'CUB',
        name: 'Cuba',
        eu: false,
    },
    {
        code: 'CUW',
        name: 'Curaçao',
        eu: false,
    },
    {
        code: 'CYP',
        name: 'Cyprus',
        eu: true,
    },
    {
        code: 'CZE',
        name: 'Czech Republic',
        eu: true,
    },
    {
        code: 'DNK',
        name: 'Denmark',
        eu: true,
    },
    {
        code: 'DJI',
        name: 'Djibouti',
        eu: false,
    },
    {
        code: 'DMA',
        name: 'Dominica',
        eu: false,
    },
    {
        code: 'DOM',
        name: 'Dominican Republic',
        eu: false,
    },
    {
        code: 'ECU',
        name: 'Ecuador',
        eu: false,
    },
    {
        code: 'EGY',
        name: 'Egypt',
        eu: false,
    },
    {
        code: 'SLV',
        name: 'El Salvador',
        eu: false,
    },
    {
        code: 'GNQ',
        name: 'Equatorial Guinea',
        eu: false,
    },
    {
        code: 'ERI',
        name: 'Eritrea',
        eu: false,
    },
    {
        code: 'EST',
        name: 'Estonia',
        eu: true,
    },
    {
        code: 'ETH',
        name: 'Ethiopia',
        eu: false,
    },
    {
        code: 'FLK',
        name: 'Falkland Islands (Malvinas)',
        eu: false,
    },
    {
        code: 'FRO',
        name: 'Faroe Islands',
        eu: false,
    },
    {
        code: 'FJI',
        name: 'Fiji',
        eu: false,
    },
    {
        code: 'FIN',
        name: 'Finland',
        eu: true,
    },
    {
        code: 'FRA',
        name: 'France',
        eu: true,
    },
    {
        code: 'GUF',
        name: 'French Guiana',
        eu: false,
    },
    {
        code: 'PYF',
        name: 'French Polynesia',
        eu: false,
    },
    {
        code: 'ATF',
        name: 'French Southern Territories',
        eu: false,
    },
    {
        code: 'GAB',
        name: 'Gabon',
        eu: false,
    },
    {
        code: 'GMB',
        name: 'Gambia',
        eu: false,
    },
    {
        code: 'GEO',
        name: 'Georgia',
        eu: false,
    },
    {
        code: 'DEU',
        name: 'Germany',
        eu: true,
    },
    {
        code: 'GHA',
        name: 'Ghana',
        eu: false,
    },
    {
        code: 'GIB',
        name: 'Gibraltar',
        eu: false,
    },
    {
        code: 'GRC',
        name: 'Greece',
        eu: true,
    },
    {
        code: 'GRL',
        name: 'Greenland',
        eu: false,
    },
    {
        code: 'GRD',
        name: 'Grenada',
        eu: false,
    },
    {
        code: 'GLP',
        name: 'Guadeloupe',
        eu: false,
    },
    {
        code: 'GUM',
        name: 'Guam',
        eu: false,
    },
    {
        code: 'GTM',
        name: 'Guatemala',
        eu: false,
    },
    {
        code: 'GGY',
        name: 'Guernsey',
        eu: false,
    },
    {
        code: 'GIN',
        name: 'Guinea',
        eu: false,
    },
    {
        code: 'GNB',
        name: 'Guinea-Bissau',
        eu: false,
    },
    {
        code: 'GUY',
        name: 'Guyana',
        eu: false,
    },
    {
        code: 'HTI',
        name: 'Haiti',
        eu: false,
    },
    {
        code: 'HMD',
        name: 'Heard Island and McDonald Islands',
        eu: false,
    },
    {
        code: 'VAT',
        name: 'Holy See (Vatican City State)',
        eu: false,
    },
    {
        code: 'HND',
        name: 'Honduras',
        eu: false,
    },
    {
        code: 'HKG',
        name: 'Hong Kong',
        eu: false,
    },
    {
        code: 'HUN',
        name: 'Hungary',
        eu: true,
    },
    {
        code: 'ISL',
        name: 'Iceland',
        eu: false,
    },
    {
        code: 'IND',
        name: 'India',
        eu: false,
    },
    {
        code: 'IDN',
        name: 'Indonesia',
        eu: false,
    },
    {
        code: 'IRN',
        name: 'Iran, Islamic Republic of',
        eu: false,
    },
    {
        code: 'IRQ',
        name: 'Iraq',
        eu: false,
    },
    {
        code: 'IRL',
        name: 'Ireland',
        eu: true,
    },
    {
        code: 'IMN',
        name: 'Isle of Man',
        eu: false,
    },
    {
        code: 'ISR',
        name: 'Israel',
        eu: false,
    },
    {
        code: 'ITA',
        name: 'Italy',
        eu: true,
    },
    {
        code: 'JAM',
        name: 'Jamaica',
        eu: false,
    },
    {
        code: 'JPN',
        name: 'Japan',
        eu: false,
    },
    {
        code: 'JEY',
        name: 'Jersey',
        eu: false,
    },
    {
        code: 'JOR',
        name: 'Jordan',
        eu: false,
    },
    {
        code: 'KAZ',
        name: 'Kazakhstan',
        eu: false,
    },
    {
        code: 'KEN',
        name: 'Kenya',
        eu: false,
    },
    {
        code: 'KIR',
        name: 'Kiribati',
        eu: false,
    },
    {
        code: 'PRK',
        name: "Korea, Democratic People's Republic of",
        eu: false,
    },
    {
        code: 'KOR',
        name: 'Korea, Republic of',
        eu: false,
    },
    {
        code: 'KWT',
        name: 'Kuwait',
        eu: false,
    },
    {
        code: 'KGZ',
        name: 'Kyrgyzstan',
        eu: false,
    },
    {
        code: 'LAO',
        name: "Lao People's Democratic Republic",
        eu: false,
    },
    {
        code: 'LVA',
        name: 'Latvia',
        eu: true,
    },
    {
        code: 'LBN',
        name: 'Lebanon',
        eu: false,
    },
    {
        code: 'LSO',
        name: 'Lesotho',
        eu: false,
    },
    {
        code: 'LBR',
        name: 'Liberia',
        eu: false,
    },
    {
        code: 'LBY',
        name: 'Libya',
        eu: false,
    },
    {
        code: 'LIE',
        name: 'Liechtenstein',
        eu: false,
    },
    {
        code: 'LTU',
        name: 'Lithuania',
        eu: true,
    },
    {
        code: 'LUX',
        name: 'Luxembourg',
        eu: true,
    },
    {
        code: 'MAC',
        name: 'Macao',
        eu: false,
    },
    {
        code: 'MKD',
        name: 'Macedonia, the former Yugoslav Republic of',
        eu: false,
    },
    {
        code: 'MDG',
        name: 'Madagascar',
        eu: false,
    },
    {
        code: 'MWI',
        name: 'Malawi',
        eu: false,
    },
    {
        code: 'MYS',
        name: 'Malaysia',
        eu: false,
    },
    {
        code: 'MDV',
        name: 'Maldives',
        eu: false,
    },
    {
        code: 'MLI',
        name: 'Mali',
        eu: false,
    },
    {
        code: 'MLT',
        name: 'Malta',
        eu: true,
    },
    {
        code: 'MHL',
        name: 'Marshall Islands',
        eu: false,
    },
    {
        code: 'MTQ',
        name: 'Martinique',
        eu: false,
    },
    {
        code: 'MRT',
        name: 'Mauritania',
        eu: false,
    },
    {
        code: 'MUS',
        name: 'Mauritius',
        eu: false,
    },
    {
        code: 'MYT',
        name: 'Mayotte',
        eu: false,
    },
    {
        code: 'MEX',
        name: 'Mexico',
        eu: false,
    },
    {
        code: 'FSM',
        name: 'Micronesia, Federated States of',
        eu: false,
    },
    {
        code: 'MDA',
        name: 'Moldova, Republic of',
        eu: false,
    },
    {
        code: 'MCO',
        name: 'Monaco',
        eu: false,
    },
    {
        code: 'MNG',
        name: 'Mongolia',
        eu: false,
    },
    {
        code: 'MNE',
        name: 'Montenegro',
        eu: false,
    },
    {
        code: 'MSR',
        name: 'Montserrat',
        eu: false,
    },
    {
        code: 'MAR',
        name: 'Morocco',
        eu: false,
    },
    {
        code: 'MOZ',
        name: 'Mozambique',
        eu: false,
    },
    {
        code: 'MMR',
        name: 'Myanmar',
        eu: false,
    },
    {
        code: 'NAM',
        name: 'Namibia',
        eu: false,
    },
    {
        code: 'NRU',
        name: 'Nauru',
        eu: false,
    },
    {
        code: 'NPL',
        name: 'Nepal',
        eu: false,
    },
    {
        code: 'NLD',
        name: 'Netherlands',
        eu: true,
    },
    {
        code: 'NCL',
        name: 'New Caledonia',
        eu: false,
    },
    {
        code: 'NZL',
        name: 'New Zealand',
        eu: false,
    },
    {
        code: 'NIC',
        name: 'Nicaragua',
        eu: false,
    },
    {
        code: 'NER',
        name: 'Niger',
        eu: false,
    },
    {
        code: 'NGA',
        name: 'Nigeria',
        eu: false,
    },
    {
        code: 'NIU',
        name: 'Niue',
        eu: false,
    },
    {
        code: 'NFK',
        name: 'Norfolk Island',
        eu: false,
    },
    {
        code: 'MNP',
        name: 'Northern Mariana Islands',
        eu: false,
    },
    {
        code: 'NOR',
        name: 'Norway',
        eu: false,
    },
    {
        code: 'OMN',
        name: 'Oman',
        eu: false,
    },
    {
        code: 'PAK',
        name: 'Pakistan',
        eu: false,
    },
    {
        code: 'PLW',
        name: 'Palau',
        eu: false,
    },
    {
        code: 'PSE',
        name: 'Palestinian Territory, Occupied',
        eu: false,
    },
    {
        code: 'PAN',
        name: 'Panama',
        eu: false,
    },
    {
        code: 'PNG',
        name: 'Papua New Guinea',
        eu: false,
    },
    {
        code: 'PRY',
        name: 'Paraguay',
        eu: false,
    },
    {
        code: 'PER',
        name: 'Peru',
        eu: false,
    },
    {
        code: 'PHL',
        name: 'Philippines',
        eu: false,
    },
    {
        code: 'PCN',
        name: 'Pitcairn',
        eu: false,
    },
    {
        code: 'POL',
        name: 'Poland',
        eu: true,
    },
    {
        code: 'PRT',
        name: 'Portugal',
        eu: true,
    },
    {
        code: 'PRI',
        name: 'Puerto Rico',
        eu: false,
    },
    {
        code: 'QAT',
        name: 'Qatar',
        eu: false,
    },
    {
        code: 'REU',
        name: 'Réunion',
        eu: false,
    },
    {
        code: 'ROU',
        name: 'Romania',
        eu: true,
    },
    {
        code: 'RUS',
        name: 'Russian Federation',
        eu: false,
    },
    {
        code: 'RWA',
        name: 'Rwanda',
        eu: false,
    },
    {
        code: 'BLM',
        name: 'Saint Barthélemy',
        eu: false,
    },
    {
        code: 'SHN',
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        eu: false,
    },
    {
        code: 'KNA',
        name: 'Saint Kitts and Nevis',
        eu: false,
    },
    {
        code: 'LCA',
        name: 'Saint Lucia',
        eu: false,
    },
    {
        code: 'MAF',
        name: 'Saint Martin (French part)',
        eu: false,
    },
    {
        code: 'SPM',
        name: 'Saint Pierre and Miquelon',
        eu: false,
    },
    {
        code: 'VCT',
        name: 'Saint Vincent and the Grenadines',
        eu: false,
    },
    {
        code: 'WSM',
        name: 'Samoa',
        eu: false,
    },
    {
        code: 'SMR',
        name: 'San Marino',
        eu: false,
    },
    {
        code: 'STP',
        name: 'Sao Tome and Principe',
        eu: false,
    },
    {
        code: 'SAU',
        name: 'Saudi Arabia',
        eu: false,
    },
    {
        code: 'SEN',
        name: 'Senegal',
        eu: false,
    },
    {
        code: 'SRB',
        name: 'Serbia',
        eu: false,
    },
    {
        code: 'SYC',
        name: 'Seychelles',
        eu: false,
    },
    {
        code: 'SLE',
        name: 'Sierra Leone',
        eu: false,
    },
    {
        code: 'SGP',
        name: 'Singapore',
        eu: false,
    },
    {
        code: 'SXM',
        name: 'Sint Maarten (Dutch part)',
        eu: false,
    },
    {
        code: 'SVK',
        name: 'Slovakia',
        eu: true,
    },
    {
        code: 'SVN',
        name: 'Slovenia',
        eu: true,
    },
    {
        code: 'SLB',
        name: 'Solomon Islands',
        eu: false,
    },
    {
        code: 'SOM',
        name: 'Somalia',
        eu: false,
    },
    {
        code: 'ZAF',
        name: 'South Africa',
        eu: false,
    },
    {
        code: 'SGS',
        name: 'South Georgia and the South Sandwich Islands',
        eu: false,
    },
    {
        code: 'SSD',
        name: 'South Sudan',
        eu: false,
    },
    {
        code: 'ESP',
        name: 'Spain',
        eu: true,
    },
    {
        code: 'LKA',
        name: 'Sri Lanka',
        eu: false,
    },
    {
        code: 'SDN',
        name: 'Sudan',
        eu: false,
    },
    {
        code: 'SUR',
        name: 'Suriname',
        eu: false,
    },
    {
        code: 'SJM',
        name: 'Svalbard and Jan Mayen',
        eu: false,
    },
    {
        code: 'SWZ',
        name: 'Swaziland',
        eu: false,
    },
    {
        code: 'SWE',
        name: 'Sweden',
        eu: true,
    },
    {
        code: 'CHE',
        name: 'Switzerland',
        eu: false,
    },
    {
        code: 'SYR',
        name: 'Syrian Arab Republic',
        eu: false,
    },
    {
        code: 'TWN',
        name: 'Taiwan, Province of China',
        eu: false,
    },
    {
        code: 'TJK',
        name: 'Tajikistan',
        eu: false,
    },
    {
        code: 'TZA',
        name: 'Tanzania, United Republic of',
        eu: false,
    },
    {
        code: 'THA',
        name: 'Thailand',
        eu: false,
    },
    {
        code: 'TLS',
        name: 'Timor-Leste',
        eu: false,
    },
    {
        code: 'TGO',
        name: 'Togo',
        eu: false,
    },
    {
        code: 'TKL',
        name: 'Tokelau',
        eu: false,
    },
    {
        code: 'TON',
        name: 'Tonga',
        eu: false,
    },
    {
        code: 'TTO',
        name: 'Trinidad and Tobago',
        eu: false,
    },
    {
        code: 'TUN',
        name: 'Tunisia',
        eu: false,
    },
    {
        code: 'TUR',
        name: 'Turkey',
        eu: false,
    },
    {
        code: 'TKM',
        name: 'Turkmenistan',
        eu: false,
    },
    {
        code: 'TCA',
        name: 'Turks and Caicos Islands',
        eu: false,
    },
    {
        code: 'TUV',
        name: 'Tuvalu',
        eu: false,
    },
    {
        code: 'UGA',
        name: 'Uganda',
        eu: false,
    },
    {
        code: 'UKR',
        name: 'Ukraine',
        eu: false,
    },
    {
        code: 'ARE',
        name: 'United Arab Emirates',
        eu: false,
    },
    {
        code: 'GBR',
        name: 'United Kingdom',
        eu: true,
    },
    {
        code: 'USA',
        name: 'United States',
        eu: false,
    },
    {
        code: 'UMI',
        name: 'United States Minor Outlying Islands',
        eu: false,
    },
    {
        code: 'URY',
        name: 'Uruguay',
        eu: false,
    },
    {
        code: 'UZB',
        name: 'Uzbekistan',
        eu: false,
    },
    {
        code: 'VUT',
        name: 'Vanuatu',
        eu: false,
    },
    {
        code: 'VEN',
        name: 'Venezuela, Bolivarian Republic of',
        eu: false,
    },
    {
        code: 'VNM',
        name: 'Viet Nam',
        eu: false,
    },
    {
        code: 'VGB',
        name: 'Virgin Islands, British',
        eu: false,
    },
    {
        code: 'VIR',
        name: 'Virgin Islands, U.S.',
        eu: false,
    },
    {
        code: 'WLF',
        name: 'Wallis and Futuna',
        eu: false,
    },
    {
        code: 'ESH',
        name: 'Western Sahara',
        eu: false,
    },
    {
        code: 'YEM',
        name: 'Yemen',
        eu: false,
    },
    {
        code: 'ZMB',
        name: 'Zambia',
        eu: false,
    },
    {
        code: 'ZWE',
        name: 'Zimbabwe',
        eu: false,
    },
]

export const countryLabeledList = [
    { label: 'Afghanistan', value: 'Afghanistan', code: 'AF' },
    { label: 'Albania', value: 'Albania', code: 'AL' },
    { label: 'Algeria', value: 'Algeria', code: 'DZ' },
    { label: 'American Samoa', value: 'American Samoa', code: 'AS' },
    { label: 'Andorra', value: 'Andorra', code: 'AD' },
    { label: 'Angola', value: 'Angola', code: 'AO' },
    { label: 'Anguilla', value: 'Anguilla', code: 'AI' },
    { label: 'Antarctica', value: 'Antarctica', code: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'Antigua and Barbuda', code: 'AG' },
    { label: 'Argentina', value: 'Argentina', code: 'AR' },
    { label: 'Armenia', value: 'Armenia', code: 'AM' },
    { label: 'Aruba', value: 'Aruba', code: 'AW' },
    { label: 'Australia', value: 'Australia', code: 'AU' },
    { label: 'Austria', value: 'Austria', code: 'AT' },
    { label: 'Azerbaijan', value: 'Azerbaijan', code: 'AZ' },
    { label: 'Bahamas ', value: 'Bahamas ', code: 'BS' },
    { label: 'Bahrain', value: 'Bahrain', code: 'BH' },
    { label: 'Bangladesh', value: 'Bangladesh', code: 'BD' },
    { label: 'Barbados', value: 'Barbados', code: 'BB' },
    { label: 'Belarus', value: 'Belarus', code: 'BY' },
    { label: 'Belgium', value: 'Belgium', code: 'BE' },
    { label: 'Belize', value: 'Belize', code: 'BZ' },
    { label: 'Benin', value: 'Benin', code: 'BJ' },
    { label: 'Bermuda', value: 'Bermuda', code: 'BM' },
    { label: 'Bhutan', value: 'Bhutan', code: 'BT' },
    { label: 'Bolivia', value: 'Bolivia', code: 'BO' },
    { label: 'Bonaire, Sint Eustatius and Saba', value: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
    { label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', code: 'BA' },
    { label: 'Botswana', value: 'Botswana', code: 'BW' },
    { label: 'Bouvet Island', value: 'Bouvet Island', code: 'BV' },
    { label: 'Brazil', value: 'Brazil', code: 'BR' },
    { label: 'British Indian Ocean Territory ', value: 'British Indian Ocean Territory ', code: 'IO' },
    { label: 'Brunei Darussalam', value: 'Brunei Darussalam', code: 'BN' },
    { label: 'Bulgaria', value: 'Bulgaria', code: 'BG' },
    { label: 'Burkina Faso', value: 'Burkina Faso', code: 'BF' },
    { label: 'Burundi', value: 'Burundi', code: 'BI' },
    { label: 'Cabo Verde', value: 'Cabo Verde', code: 'CV' },
    { label: 'Cambodia', value: 'Cambodia', code: 'KH' },
    { label: 'Cameroon', value: 'Cameroon', code: 'CM' },
    { label: 'Canada', value: 'Canada', code: 'CA' },
    { label: 'Cayman Islands ', value: 'Cayman Islands ', code: 'KY' },
    { label: 'Central African Republic ', value: 'Central African Republic ', code: 'CF' },
    { label: 'Chad', value: 'Chad', code: 'TD' },
    { label: 'Chile', value: 'Chile', code: 'CL' },
    { label: 'China', value: 'China', code: 'CN' },
    { label: 'Christmas Island', value: 'Christmas Island', code: 'CX' },
    { label: 'Cocos (Keeling) Islands ', value: 'Cocos (Keeling) Islands ', code: 'CC' },
    { label: 'Colombia', value: 'Colombia', code: 'CO' },
    { label: 'Comoros ', value: 'Comoros ', code: 'KM' },
    { label: 'Congo (the Democratic Republic of the)', value: 'Congo (the Democratic Republic of the)', code: 'CD' },
    { label: 'Congo ', value: 'Congo ', code: 'CG' },
    { label: 'Cook Islands ', value: 'Cook Islands ', code: 'CK' },
    { label: 'Costa Rica', value: 'Costa Rica', code: 'CR' },
    { label: 'Croatia', value: 'Croatia', code: 'HR' },
    { label: 'Cuba', value: 'Cuba', code: 'CU' },
    { label: 'Curaçao', value: 'Curaçao', code: 'CW' },
    { label: 'Cyprus', value: 'Cyprus', code: 'CY' },
    { label: 'Czechia', value: 'Czechia', code: 'CZ' },
    { label: "Côte d'Ivoire", value: "Côte d'Ivoire", code: 'CI' },
    { label: 'Denmark', value: 'Denmark', code: 'DK' },
    { label: 'Djibouti', value: 'Djibouti', code: 'DJ' },
    { label: 'Dominica', value: 'Dominica', code: 'DM' },
    { label: 'República Dominicana', value: 'República Dominicana', code: 'DO' },
    { label: 'Ecuador', value: 'Ecuador', code: 'EC' },
    { label: 'Egypt', value: 'Egypt', code: 'EG' },
    { label: 'El Salvador', value: 'El Salvador', code: 'SV' },
    { label: 'Equatorial Guinea', value: 'Equatorial Guinea', code: 'GQ' },
    { label: 'Eritrea', value: 'Eritrea', code: 'ER' },
    { label: 'Estonia', value: 'Estonia', code: 'EE' },
    { label: 'Eswatini', value: 'Eswatini', code: 'SZ' },
    { label: 'Ethiopia', value: 'Ethiopia', code: 'ET' },
    { label: 'Falkland Islands  [Malvinas]', value: 'Falkland Islands  [Malvinas]', code: 'FK' },
    { label: 'Faroe Islands ', value: 'Faroe Islands ', code: 'FO' },
    { label: 'Fiji', value: 'Fiji', code: 'FJ' },
    { label: 'Finland', value: 'Finland', code: 'FI' },
    { label: 'France', value: 'France', code: 'FR' },
    { label: 'French Guiana', value: 'French Guiana', code: 'GF' },
    { label: 'French Polynesia', value: 'French Polynesia', code: 'PF' },
    { label: 'French Southern Territories ', value: 'French Southern Territories ', code: 'TF' },
    { label: 'Gabon', value: 'Gabon', code: 'GA' },
    { label: 'Gambia ', value: 'Gambia ', code: 'GM' },
    { label: 'Georgia', value: 'Georgia', code: 'GE' },
    { label: 'Germany', value: 'Germany', code: 'DE' },
    { label: 'Ghana', value: 'Ghana', code: 'GH' },
    { label: 'Gibraltar', value: 'Gibraltar', code: 'GI' },
    { label: 'Greece', value: 'Greece', code: 'GR' },
    { label: 'Greenland', value: 'Greenland', code: 'GL' },
    { label: 'Grenada', value: 'Grenada', code: 'GD' },
    { label: 'Guadeloupe', value: 'Guadeloupe', code: 'GP' },
    { label: 'Guam', value: 'Guam', code: 'GU' },
    { label: 'Guatemala', value: 'Guatemala', code: 'GT' },
    { label: 'Guernsey', value: 'Guernsey', code: 'GG' },
    { label: 'Guinea', value: 'Guinea', code: 'GN' },
    { label: 'Guinea-Bissau', value: 'Guinea-Bissau', code: 'GW' },
    { label: 'Guyana', value: 'Guyana', code: 'GY' },
    { label: 'Haiti', value: 'Haiti', code: 'HT' },
    { label: 'Heard Island and McDonald Islands', value: 'Heard Island and McDonald Islands', code: 'HM' },
    { label: 'Holy See ', value: 'Holy See ', code: 'VA' },
    { label: 'Honduras', value: 'Honduras', code: 'HN' },
    { label: 'Hong Kong', value: 'Hong Kong', code: 'HK' },
    { label: 'Hungary', value: 'Hungary', code: 'HU' },
    { label: 'Iceland', value: 'Iceland', code: 'IS' },
    { label: 'India', value: 'India', code: 'IN' },
    { label: 'Indonesia', value: 'Indonesia', code: 'ID' },
    { label: 'Iran (Islamic Republic of)', value: 'Iran (Islamic Republic of)', code: 'IR' },
    { label: 'Iraq', value: 'Iraq', code: 'IQ' },
    { label: 'Ireland', value: 'Ireland', code: 'IE' },
    { label: 'Isle of Man', value: 'Isle of Man', code: 'IM' },
    { label: 'Israel', value: 'Israel', code: 'IL' },
    { label: 'Italy', value: 'Italy', code: 'IT' },
    { label: 'Jamaica', value: 'Jamaica', code: 'JM' },
    { label: 'Japan', value: 'Japan', code: 'JP' },
    { label: 'Jersey', value: 'Jersey', code: 'JE' },
    { label: 'Jordan', value: 'Jordan', code: 'JO' },
    { label: 'Kazakhstan', value: 'Kazakhstan', code: 'KZ' },
    { label: 'Kenya', value: 'Kenya', code: 'KE' },
    { label: 'Kiribati', value: 'Kiribati', code: 'KI' },
    {
        label: "Korea (the Democratic People's Republic of)",
        value: "Korea (the Democratic People's Republic of)",
        code: 'KP',
    },
    { label: 'Korea (the Republic of)', value: 'Korea (the Republic of)', code: 'KR' },
    { label: 'Kuwait', value: 'Kuwait', code: 'KW' },
    { label: 'Kyrgyzstan', value: 'Kyrgyzstan', code: 'KG' },
    { label: "Lao People's Democratic Republic ", value: "Lao People's Democratic Republic ", code: 'LA' },
    { label: 'Latvia', value: 'Latvia', code: 'LV' },
    { label: 'Lebanon', value: 'Lebanon', code: 'LB' },
    { label: 'Lesotho', value: 'Lesotho', code: 'LS' },
    { label: 'Liberia', value: 'Liberia', code: 'LR' },
    { label: 'Libya', value: 'Libya', code: 'LY' },
    { label: 'Liechtenstein', value: 'Liechtenstein', code: 'LI' },
    { label: 'Lithuania', value: 'Lithuania', code: 'LT' },
    { label: 'Luxembourg', value: 'Luxembourg', code: 'LU' },
    { label: 'Macao', value: 'Macao', code: 'MO' },
    { label: 'Madagascar', value: 'Madagascar', code: 'MG' },
    { label: 'Malawi', value: 'Malawi', code: 'MW' },
    { label: 'Malaysia', value: 'Malaysia', code: 'MY' },
    { label: 'Maldives', value: 'Maldives', code: 'MV' },
    { label: 'Mali', value: 'Mali', code: 'ML' },
    { label: 'Malta', value: 'Malta', code: 'MT' },
    { label: 'Marshall Islands ', value: 'Marshall Islands ', code: 'MH' },
    { label: 'Martinique', value: 'Martinique', code: 'MQ' },
    { label: 'Mauritania', value: 'Mauritania', code: 'MR' },
    { label: 'Mauritius', value: 'Mauritius', code: 'MU' },
    { label: 'Mayotte', value: 'Mayotte', code: 'YT' },
    { label: 'Mexico', value: 'Mexico', code: 'MX' },
    { label: 'Micronesia (Federated States of)', value: 'Micronesia (Federated States of)', code: 'FM' },
    { label: 'Moldova (the Republic of)', value: 'Moldova (the Republic of)', code: 'MD' },
    { label: 'Monaco', value: 'Monaco', code: 'MC' },
    { label: 'Mongolia', value: 'Mongolia', code: 'MN' },
    { label: 'Montenegro', value: 'Montenegro', code: 'ME' },
    { label: 'Montserrat', value: 'Montserrat', code: 'MS' },
    { label: 'Morocco', value: 'Morocco', code: 'MA' },
    { label: 'Mozambique', value: 'Mozambique', code: 'MZ' },
    { label: 'Myanmar', value: 'Myanmar', code: 'MM' },
    { label: 'Namibia', value: 'Namibia', code: 'NA' },
    { label: 'Nauru', value: 'Nauru', code: 'NR' },
    { label: 'Nepal', value: 'Nepal', code: 'NP' },
    { label: 'Netherlands ', value: 'Netherlands ', code: 'NL' },
    { label: 'New Caledonia', value: 'New Caledonia', code: 'NC' },
    { label: 'New Zealand', value: 'New Zealand', code: 'NZ' },
    { label: 'Nicaragua', value: 'Nicaragua', code: 'NI' },
    { label: 'Niger ', value: 'Niger ', code: 'NE' },
    { label: 'Nigeria', value: 'Nigeria', code: 'NG' },
    { label: 'Niue', value: 'Niue', code: 'NU' },
    { label: 'Norfolk Island', value: 'Norfolk Island', code: 'NF' },
    { label: 'Northern Mariana Islands ', value: 'Northern Mariana Islands ', code: 'MP' },
    { label: 'Norway', value: 'Norway', code: 'NO' },
    { label: 'Oman', value: 'Oman', code: 'OM' },
    { label: 'Pakistan', value: 'Pakistan', code: 'PK' },
    { label: 'Palau', value: 'Palau', code: 'PW' },
    { label: 'Palestine, State of', value: 'Palestine, State of', code: 'PS' },
    { label: 'Panama', value: 'Panama', code: 'PA' },
    { label: 'Papua New Guinea', value: 'Papua New Guinea', code: 'PG' },
    { label: 'Paraguay', value: 'Paraguay', code: 'PY' },
    { label: 'Peru', value: 'Peru', code: 'PE' },
    { label: 'Philippines ', value: 'Philippines ', code: 'PH' },
    { label: 'Pitcairn', value: 'Pitcairn', code: 'PN' },
    { label: 'Poland', value: 'Poland', code: 'PL' },
    { label: 'Portugal', value: 'Portugal', code: 'PT' },
    { label: 'Puerto Rico', value: 'Puerto Rico', code: 'PR' },
    { label: 'Qatar', value: 'Qatar', code: 'QA' },
    { label: 'Republic of North Macedonia', value: 'Republic of North Macedonia', code: 'MK' },
    { label: 'Romania', value: 'Romania', code: 'RO' },
    { label: 'Russian Federation ', value: 'Russian Federation ', code: 'RU' },
    { label: 'Rwanda', value: 'Rwanda', code: 'RW' },
    { label: 'Réunion', value: 'Réunion', code: 'RE' },
    { label: 'Saint Barthélemy', value: 'Saint Barthélemy', code: 'BL' },
    {
        label: 'Saint Helena, Ascension and Tristan da Cunha',
        value: 'Saint Helena, Ascension and Tristan da Cunha',
        code: 'SH',
    },
    { label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis', code: 'KN' },
    { label: 'Saint Lucia', value: 'Saint Lucia', code: 'LC' },
    { label: 'Saint Martin (French part)', value: 'Saint Martin (French part)', code: 'MF' },
    { label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon', code: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines', code: 'VC' },
    { label: 'Samoa', value: 'Samoa', code: 'WS' },
    { label: 'San Marino', value: 'San Marino', code: 'SM' },
    { label: 'Sao Tome and Principe', value: 'Sao Tome and Principe', code: 'ST' },
    { label: 'Saudi Arabia', value: 'Saudi Arabia', code: 'SA' },
    { label: 'Senegal', value: 'Senegal', code: 'SN' },
    { label: 'Serbia', value: 'Serbia', code: 'RS' },
    { label: 'Seychelles', value: 'Seychelles', code: 'SC' },
    { label: 'Sierra Leone', value: 'Sierra Leone', code: 'SL' },
    { label: 'Singapore', value: 'Singapore', code: 'SG' },
    { label: 'Sint Maarten', value: 'Sint Maarten', code: 'SX' },
    { label: 'Slovakia', value: 'Slovakia', code: 'SK' },
    { label: 'Slovenia', value: 'Slovenia', code: 'SI' },
    { label: 'Solomon Islands', value: 'Solomon Islands', code: 'SB' },
    { label: 'Somalia', value: 'Somalia', code: 'SO' },
    { label: 'South Africa', value: 'South Africa', code: 'ZA' },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'South Georgia and the South Sandwich Islands',
        code: 'GS',
    },
    { label: 'South Sudan', value: 'South Sudan', code: 'SS' },
    { label: 'Spain', value: 'Spain', code: 'ES' },
    { label: 'Sri Lanka', value: 'Sri Lanka', code: 'LK' },
    { label: 'Sudan ', value: 'Sudan ', code: 'SD' },
    { label: 'Surilabel', value: 'Surilabel', code: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen', code: 'SJ' },
    { label: 'Sweden', value: 'Sweden', code: 'SE' },
    { label: 'Switzerland', value: 'Switzerland', code: 'CH' },
    { label: 'Syrian Arab Republic', value: 'Syrian Arab Republic', code: 'SY' },
    { label: 'Taiwan (Province of China)', value: 'Taiwan (Province of China)', code: 'TW' },
    { label: 'Tajikistan', value: 'Tajikistan', code: 'TJ' },
    { label: 'Tanzania, United Republic', value: 'Tanzania, United Republic', code: 'TZ' },
    { label: 'Thailand', value: 'Thailand', code: 'TH' },
    { label: 'Timor-Leste', value: 'Timor-Leste', code: 'TL' },
    { label: 'Togo', value: 'Togo', code: 'TG' },
    { label: 'Tokelau', value: 'Tokelau', code: 'TK' },
    { label: 'Tonga', value: 'Tonga', code: 'TO' },
    { label: 'Trinidad and Tobago', value: 'Trinidad and Tobago', code: 'TT' },
    { label: 'Tunisia', value: 'Tunisia', code: 'TN' },
    { label: 'Turkey', value: 'Turkey', code: 'TR' },
    { label: 'Turkmenistan', value: 'Turkmenistan', code: 'TM' },
    { label: 'Turks and Caicos Islands ', value: 'Turks and Caicos Islands ', code: 'TC' },
    { label: 'Tuvalu', value: 'Tuvalu', code: 'TV' },
    { label: 'Uganda', value: 'Uganda', code: 'UG' },
    { label: 'Ukraine', value: 'Ukraine', code: 'UA' },
    { label: 'United Arab Emirates ', value: 'United Arab Emirates ', code: 'AE' },
    {
        label: 'United Kingdom of Great Britain and Northern Ireland ',
        value: 'United Kingdom of Great Britain and Northern Ireland ',
        code: 'GB',
    },
    { label: 'United States Minor Outlying Islands ', value: 'United States Minor Outlying Islands ', code: 'UM' },
    { label: 'United States', value: 'United States', code: 'US' },
    { label: 'Uruguay', value: 'Uruguay', code: 'UY' },
    { label: 'Uzbekistan', value: 'Uzbekistan', code: 'UZ' },
    { label: 'Vanuatu', value: 'Vanuatu', code: 'VU' },
    { label: 'Venezuela', value: 'Venezuela', code: 'VE' },
    { label: 'Viet Nam', value: 'Viet Nam', code: 'VN' },
    { label: 'Virgin Islands', value: 'Virgin Islands', code: 'VG' },
    { label: 'Virgin Islands (U.S.)', value: 'Virgin Islands (U.S.)', code: 'VI' },
    { label: 'Wallis and Futuna', value: 'Wallis and Futuna', code: 'WF' },
    { label: 'Western Sahara', value: 'Western Sahara', code: 'EH' },
    { label: 'Yemen', value: 'Yemen', code: 'YE' },
    { label: 'Zambia', value: 'Zambia', code: 'ZM' },
    { label: 'Zimbabwe', value: 'Zimbabwe', code: 'ZW' },
    { label: 'Åland Islands', value: 'Åland Islands', code: 'AX' },
]
