/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import 'antd/dist/reset.css'
import { motion } from 'framer-motion'
// import loadingGif from '../assets/gifs/loadingGif.gif'

import { Row, Typography, Space, Spin } from 'antd'

// const inter = Inter({ subsets: ['latin'] })

import { BsAirplane, BsCreditCard, BsSquare, BsStripe } from 'react-icons/bs'
import { FaRobot } from 'react-icons/fa'

export default function LoadingFullScreen({ loading = false }) {
    const IconGlobe = ({ icon, left, top, variant }) => {
        return (
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 1.5 }}
                whileDrag={{ scale: 1.5, rotate: -60 }}
                drag
                animate={[variant]}
                variants={{
                    variant1: {
                        y: [-10, 20],
                        x: [-30, 60],
                        rotate: 0,
                        transition: {
                            delay: 0.6,
                            duration: 10,
                            repeat: Infinity,
                            repeatType: 'mirror',
                        },
                    },
                    variant2: {
                        y: [20, -30],
                        x: [60, -70],
                        rotate: 0,
                        transition: {
                            delay: 0.6,
                            duration: 10,
                            repeat: Infinity,
                            repeatType: 'mirror',
                        },
                    },
                    variant3: {
                        y: [20, -30],
                        x: [-50, 90],
                        rotate: 0,
                        transition: {
                            delay: 0.6,
                            duration: 10,
                            repeat: Infinity,
                            repeatType: 'mirror',
                        },
                    },
                }}
                style={{
                    position: 'relative',
                    height: '50px',
                    width: '50px',
                    backgroundColor: '#F6F5F9',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
                    left,
                    top,
                }}
            >
                {icon}
            </motion.div>
        )
    }

    const IconBrand = () => {
        return (
            <div
                style={{
                    position: 'relative',
                    // height: '40px',
                    // width: '40px',
                    // backgroundColor: '#8666FF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    // borderRadius: '50%',
                    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.04)',
                    // top: '50%'
                    //MAKE THE LEFT AND TOP DYNAMIC AND RANDOM WITH ANY NUMBER BETWEEN 0 AND 100
                }}
            >
                <p
                    className="brand"
                    style={{
                        fontSize: '30px',
                        color: '#101840',
                        marginTop: '0%',
                        transform: 'translateY(-14%)',
                    }}
                >
                    gigstack
                </p>
                <Spin size="large" />
                <p
                    className=""
                    style={{
                        fontSize: '12px',
                        color: '#cecece',
                        marginTop: '25px',
                        transform: 'translateY(-14%)',
                    }}
                >
                    automatiza tu administración
                </p>
            </div>
        )
    }

    return (
        <>
            <div
                style={{
                    position: 'relative',
                    backgroundColor: '#FFFFFF',
                    height: '100vh',
                    width: '100%',
                }}
            >
                <IconGlobe icon={<BsStripe style={{ color: '' }} />} left={'25%'} top={'25%'} variant="variant1" />
                <IconGlobe icon={<BsCreditCard style={{ color: '' }} />} left={'37%'} top={'32%'} variant="variant1" />
                <IconGlobe icon={<BsSquare style={{ color: '' }} />} left={'37%'} top={'67%'} variant="variant2" />
                <IconGlobe icon={<BsAirplane style={{ color: '' }} />} left={'70%'} top={'40%'} variant="variant3" />
                <IconGlobe icon={<FaRobot style={{ color: '' }} />} left={'70%'} top={'10%'} variant="variant1" />

                <IconGlobe
                    icon={<Typography.Text style={{ fontSize: '15px' }}>🚀</Typography.Text>}
                    left={'20%'}
                    top={'40%'}
                    variant="variant2"
                />

                <IconGlobe
                    icon={<Typography.Text style={{ fontSize: '15px' }}>🤖</Typography.Text>}
                    left={'65%'}
                    top={'50%'}
                    variant="variant2"
                />

                <div
                    style={{
                        // backgroundColor: "white",
                        width: 'auto',
                        height: '140px',
                        position: 'absolute',
                        //CENTER DIV
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -100%)',
                        borderRadius: '10px',
                        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
                        zIndex: 10,
                        padding: '20px',
                    }}
                >
                    <Row justify="center" style={{ marginBottom: '25px' }}>
                        <IconBrand />
                    </Row>
                    <Row justify="center">
                        <Space direction="vertical" align="center">
                            <Typography.Title level={2}></Typography.Title>
                            {/* {!loading ? <Button type='primary' size='large' style={{
                                marginTop: '20px',
                                width: '150px',
                                background: 'linear-gradient(90deg, #7152F3 0%, #3D6FF5 100%)',
                                backgroundRepeat: 'no-repeat',
                                borderRadius: '10px',
                                boxShadow: 'none'
                            }}>Comenzar</Button> :  */}
                        </Space>
                    </Row>
                </div>
            </div>
        </>
    )
}
