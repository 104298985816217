import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { FirebaseAppProvider } from 'reactfire'
import { Provider } from 'react-redux'
import store from './settings/store'
import { ConfigProvider } from 'antd'

const root = ReactDOM.createRoot(document.getElementById('root'))

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID,
}

root.render(
    <ConfigProvider
        theme={{
            token: { colorPrimary: '#101840' },
            components: {},
        }}
    >
        <React.StrictMode>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
                <Provider store={store}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </Provider>
            </FirebaseAppProvider>
        </React.StrictMode>
    </ConfigProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
