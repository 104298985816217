export const taxRegimes = [
    { value: '601', label: 'Ley General de Personas Morales' },
    { value: '603', label: 'Personas Morales con Fines no Lucrativos' },
    { value: '605', label: 'Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { value: '606', label: 'Arrendamiento' },
    { value: '607', label: 'Régimen de Enajenación o Adquisición de Bienes' },
    { value: '608', label: 'Demás ingresos' },
    { value: '609', label: 'Consolidación' },
    // { value: "610", label: "Residentes en el Extranjero sin Establecimiento Permanente en México" },
    { value: '610', label: 'Residentes en el Extranjero' },
    { value: '611', label: 'Ingresos por Dividendos(socios y accionistas)' },
    { value: '612', label: 'Personas Físicas con Actividades Empresariales y Profesionales' },
    { value: '614', label: 'Ingresos por intereses' },
    { value: '615', label: 'Régimen de los ingresos por obtención de premios' },
    { value: '616', label: 'Sin obligaciones fiscales' },
    { value: '620', label: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { value: '621', label: 'Régimen de Incorporación Fiscal' },
    { value: '622', label: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { value: '623', label: 'Opcional para Grupos de Sociedades' },
    { value: '624', label: 'Coordinados' },
    {
        value: '625',
        label: 'Régimen de las Actividades Empresariales con ingresos a través de plataformas tecnológicas',
    },
    { value: '626', label: 'Régimen simplificado de confianza' },
    { value: '628', label: 'Hidrocarburos' },
    { value: '629', label: 'De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales' },
    { value: '630', label: 'Enajenación de acciones en bolsa de valores' },
]

export const findTaxRegime = (value) => {
    let taxRegime = taxRegimes.find((taxRegime) => taxRegime.value === value)
    return taxRegime
}

export const invoiceUsage = [
    {
        label: 'Adquisición De Mercancías',
        value: 'G01',
    },
    {
        label: 'Devoluciones Descuentos Bonificaciones',
        value: 'G02',
    },
    {
        label: 'Gastos En General',
        value: 'G03',
    },
    {
        label: 'Construcciones',
        value: 'I01',
    },
    {
        label: 'Mobiliario Y Equipo De Oficina',
        value: 'I02',
    },
    {
        label: 'Equipo de Transporte',
        value: 'I03',
    },
    {
        label: 'Equipo de Cómputo y Accesorios',
        value: 'I04',
    },
    {
        label: 'Dados, troqueles, moldes, matrices y herramental.',
        value: 'I05',
    },
    {
        label: 'Comunicaciones Telefónicas',
        value: 'I06',
    },
    {
        label: 'Comunicaciones Satelitales',
        value: 'I07',
    },
    {
        label: 'Otra Maquinaria',
        value: 'I08',
    },
    {
        label: 'Honorarios médicos, dentales y gastos hospitalarios.',
        value: 'D01',
    },
    {
        label: 'Gastos Médicos Por Incapacidad',
        value: 'D02',
    },
    {
        label: 'Gastos Funerales',
        value: 'D03',
    },
    {
        label: 'Donativos',
        value: 'D04',
    },
    {
        label: 'Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).',
        value: 'D05',
    },
    {
        label: 'Aportaciones voluntarias al SAR.',
        value: 'D06',
    },
    {
        label: 'Prima Seguros Gastos Médicos',
        value: 'D07',
    },
    {
        label: 'Gastos de transportación escolar obligatoria',
        value: 'D08',
    },
    {
        label: 'Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.',
        value: 'D09',
    },
    {
        label: 'Pagos por servicios educativos (colegiaturas)',
        value: 'D10',
    },
    {
        label: 'Sin efectos fiscales',
        value: 'S01',
    },
    {
        value: 'CP01',
        label: 'Pagos',
    },
    {
        label: 'Nómina',
        value: 'CN01',
    },
]
export const findUsage = (value) => {
    let usage = invoiceUsage.find((usage) => usage.value === value)
    return usage
}

export const paymentForms = [
    { value: '01', label: 'Efectivo' },
    { value: '02', label: 'Cheque nominativo' },
    { value: '03', label: 'Transferencia electrónica de fondos' },
    { value: '04', label: 'Tarjeta de crédito' },
    { value: '05', label: 'Monedero electrónico' },
    { value: '06', label: 'Dinero electrónico' },
    { value: '08', label: 'Vales de despensa' },
    { value: '12', label: 'Dación en pago' },
    { value: '13', label: 'Pago por subrogación' },
    { value: '14', label: 'Pago por consignación' },
    { value: '15', label: 'Condonación' },
    { value: '17', label: 'Compensación' },
    { value: '23', label: 'Novación' },
    { value: '24', label: 'Confusión' },
    { value: '25', label: 'Remisión de deuda' },
    { value: '26', label: 'Prescripción o caducidad' },
    { value: '27', label: 'A satisfacción del acreedor' },
    { value: '28', label: 'Tarjeta de débito' },
    { value: '29', label: 'Tarjeta de servicios' },
]
