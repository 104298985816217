import { FloatButton } from 'antd'
import { BsWhatsapp } from 'react-icons/bs'
import { AiOutlineMail } from 'react-icons/ai'

const CustomFloatButton = (props) => {
    const { type, phone, mail } = props

    const handleClick = () => {
        if (type === 'whatsapp' && phone) {
            window.open(`https://api.whatsapp.com/send?phone=${phone}`, '_blank')
        } else if (type === 'mail' && mail) {
            window.open(`mailto:${mail}`, '_blank')
        }
    }

    return (
        <FloatButton
            onClick={handleClick}
            icon={
                type === 'whatsapp' ? (
                    <BsWhatsapp style={{ color: 'white' }} />
                ) : (
                    <AiOutlineMail style={{ color: 'white' }} />
                )
            }
        />
    )
}

export default CustomFloatButton
