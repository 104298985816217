/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Col, Row } from 'antd'
import PropTypes from 'prop-types';


const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
            backgroundColor: 'transparent',
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
}


function CardSection({ checkbox, setCheckbox, disabled, form, setPaymentMethod }) {

CardSection.propTypes = {
    checkbox: PropTypes.object.isRequired,
    setCheckbox: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    form: PropTypes.object.isRequired,
    setPaymentMethod: PropTypes.func.isRequired,
};

    const stripe = useStripe()
    const elements = useElements()


    const handleChange = async (event) => {
        if(event.complete){
            const cel = elements.getElement(CardElement);
            const { paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cel,
                billing_details: 
                {
                    name: form.getFieldValue("name"),
                    email: form.getFieldValue("email"),
                  },
                })
            setPaymentMethod(paymentMethod)
            
            setCheckbox({
                ...checkbox,
                disabled: false,
            })
        }
    }

    return (
        <Row>
            <Col xs={24} style={{ marginTop: '10px' }}>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        padding: '10px',
                        borderRadius: '10px',
                        border: '0.1px solid #d9d9d9',
                    }}
                    className="pro-input"
                >
                    <CardElement disabled={disabled} options={CARD_ELEMENT_OPTIONS} onChange={handleChange} on/>
                </div>
            </Col>
        </Row>
    )
}
export default CardSection
