import { usePostHog } from 'posthog-js/react'
import { getItemsAmounts } from '../functions/general'
import { useState } from 'react'
import { invoiceUsage } from '../datasources/Invoice'
import { Col, Form, Row, Image, Typography, Button, Divider, message, Anchor } from 'antd'
import { CheckCircle, DownloadSimple } from '@phosphor-icons/react'
import TryGigstackCard from './TryGigstackCard'
import { InvoiceForm } from './GenerateInvoice'
import { motion } from 'framer-motion'
import { VscLoading } from 'react-icons/vsc'
import { axiosReq } from '../config/axios'
import PowerByGigPrivacy from './PowerdByGigPrivacy'
import TestModeBanner from './TestmodeBanner'

function GenerateInvoiceV2({ receipt, updateReceipt }) {
    const posthog = usePostHog()
    const {
        supportEmail,
        legal_name,
        country,
        zip,
        id,
        timestamp,
        validUntil,
        currency,
        pdf,
        livemode,
        client,
        items,
        paidIn,
        payment_form: pform,
    } = receipt
    const payment_form = typeof pform === 'string' ? pform : pform?.value

    const [success, setSuccess] = useState(false)

    const itemsAmounts = getItemsAmounts({ items: items, useDiscount: true })
    const { brand } = receipt.user
    const { logo, alias, primaryColor } = brand
    const [modalDemo, setModalDemo] = useState(false)
    const [error, setError] = useState(null)
    const [allowedUsaged, setAllowedUsaged] = useState(invoiceUsage)
    const [creating, setCreating] = useState(false)
    const [state, setState] = useState(null)
    const [downloading, setDownloading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [countryState, setCountryState] = useState(country)
    const [form] = Form.useForm()
    const [fileLinks, setFileLinks] = useState({
        pdfLink: null,
        xmlLink: null,
    })

    const currencyFormat = Intl.NumberFormat('es-MX', { style: 'currency', currency })

    const handleDownloadPdf = () => {
        const { pdfLink } = fileLinks
        if (pdfLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = pdfLink
            a.download = `${id}-${state.rfc}.pdf`
            a.click()
            a.remove()
        }
    }

    const handleDownloadXml = () => {
        const { xmlLink } = fileLinks
        if (xmlLink) {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = xmlLink
            a.download = `${id}-${state.rfc}.xml`
            a.click()
            a.remove()
        }
    }

    const DownloadInvoices = () => {
        return (
            <Row justify="center">
                <Col xs={24}>
                    <Row justify="center">
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadPdf()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar factura PDF</Typography.Text>
                            </Row>
                        </div>
                        <div
                            className="d-flex flex-row flex-center"
                            style={{
                                width: '100%',
                                padding: '15px',
                                borderRadius: '10px',
                                backgroundColor: primaryColor,
                                cursor: downloading ? 'wait' : 'pointer',
                                marginTop: '15px',
                            }}
                            onClick={() => {
                                handleDownloadXml()
                            }}
                        >
                            <Row align="middle">
                                <Typography.Text style={{ color: 'white' }}>Descargar factura XML</Typography.Text>
                            </Row>
                        </div>
                    </Row>
                </Col>
            </Row>
        )
    }

    const handleDownloadInvoice = async () => {
        try {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            if (loading) return
            setLoading(true)
            const data = await axiosReq.post('resolveReceiptDataSeats', {
                id,
                type: 'retrieve',
                rfc: state.rfc?.toUpperCase(),
            })

            setFileLinks({
                pdfLink: data.data.url[0],
                xmlLink: data.data.urlxml[0],
            })
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
            message.error(error?.response?.data?.message ?? 'Ocurrió un error')
        }
    }
    const InvoicesSuccessCard = () => {
        return (
            <motion.div initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
                <Row style={{ width: '100%' }} gutter={{ xs: 5, md: 10, lg: 12 }} justify="center">
                    <Col xs={24}>
                        <Row justify="center">
                            <Image
                                src={logo}
                                alt={alias}
                                width={80}
                                preview={false}
                                // hide on xs
                                style={{
                                    display: window.innerWidth < 768 ? 'none' : 'block',
                                }}
                            />
                        </Row>
                        <Row justify="center" style={{ marginTop: '15px' }}>
                            <Typography.Title level={4} className="h5-bold neutral-1">
                                Tu factura está lista
                            </Typography.Title>
                        </Row>
                        {fileLinks.pdfLink === null && fileLinks.xmlLink === null && (
                            <div
                                className="d-flex flex-row flex-center btn-shadow"
                                style={{
                                    width: '100%',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    backgroundColor: primaryColor,
                                    cursor: downloading ? 'wait' : 'pointer',
                                    height: '40px',
                                    marginTop: '15px',
                                }}
                                onClick={() => {
                                    handleDownloadInvoice()
                                }}
                            >
                                <Row align="middle">
                                    {downloading ? (
                                        <VscLoading
                                            style={{
                                                animation: 'spin 1s infinite linear',
                                                marginRight: '14px',
                                                color: primaryColor,
                                            }}
                                        />
                                    ) : (
                                        <>
                                            <Typography.Text style={{ color: 'white' }}>
                                                Descargar .xml y pdf
                                            </Typography.Text>
                                            <DownloadSimple
                                                style={{ marginLeft: '14px', color: 'white', fontSize: '18px' }}
                                            />
                                        </>
                                    )}
                                </Row>
                            </div>
                        )}
                        <Divider />
                        <Row justify="center">
                            <TryGigstackCard type="autoFactura" />
                            <PowerByGigPrivacy />
                        </Row>
                        {/* <Row justify="center">
                            <div className="d-flex flex-column flex-center" style={{ height: '40px', width: '40px', borderRadius: '50%', backgroundColor: '#F7F9FB' }}>
                                <CheckCircle style={{ fontSize: '18px', color: primaryColor }} />
                            </div>
                        </Row> */}

                        {/* <Row justify='center'>
                            <Typography.Text type='secondary'>Hemos enviado la factura a tu correo electrónico</Typography.Text>
                        </Row> */}

                        {fileLinks.pdfLink !== null && fileLinks.xmlLink !== null && (
                            <Row justify="center" style={{ marginTop: '15px' }}>
                                <DownloadInvoices />
                            </Row>
                        )}
                    </Col>
                </Row>
            </motion.div>
        )
    }

    return (
        <>
            {!livemode && <TestModeBanner />}
            {success ? (
                <Row style={{ width: '100%' }} justify="center">
                    <Col xs={23} md={20} lg={12} xl={10} xxl={8}>
                        <InvoicesSuccessCard />
                    </Col>
                </Row>
            ) : (
                <Row justify="center" style={{ width: '100%', minHeight: '100vh', padding: 0, margin: 0 }}>
                    <Col xs={24} lg={0}>
                        <div
                            className="d-flex flex-column"
                            style={{ justifyItem: 'start', alignItems: 'start', padding: '40px 5%', width: '100%' }}
                        >
                            <Row justify="center" style={{ width: '100%' }}>
                                <Image src={logo} alt={alias} width={100} preview={false} />
                            </Row>
                            <Typography.Text
                                style={{
                                    fontSize: '25px',
                                    marginTop: '10px',
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    width: '100%',
                                }}
                            >
                                {itemsAmounts?.totalString} {currency?.toUpperCase()}
                            </Typography.Text>
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy />
                            </Row>
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: 'white', padding: '50px 5%' }}>
                        <div className="d-flex flex-column">
                            <InvoiceForm
                                receipt={receipt}
                                version={2}
                                onSuccess={(s) => {
                                    setSuccess(true)
                                    setState(s)
                                }}
                            />
                        </div>
                    </Col>
                    <Col xs={24} md={12} lg={12} style={{ backgroundColor: '', padding: '30px 9%' }}>
                        <div className="d-flex flex-column" style={{ justifyItem: 'start', alignItems: 'start' }}>
                            <Image
                                src={logo}
                                alt={alias}
                                width={80}
                                preview={false}
                                // hide on xs
                                style={{
                                    display: window.innerWidth < 768 ? 'none' : 'block',
                                }}
                            />
                            <Typography.Text className="h6-regular neutral-1" style={{ marginTop: '22px' }}>
                                Total pagado:
                            </Typography.Text>
                            <Typography.Text className="h3-bold neutral-1" style={{ marginTop: '10px' }}>
                                {currency?.toUpperCase()} {itemsAmounts?.totalString}
                            </Typography.Text>
                            <Button
                                onClick={() => {
                                    window.open(pdf)
                                }}
                                block
                                ghost
                                size="large"
                                style={{ marginTop: '20px', backgroundColor: 'white', color: 'black' }}
                                icon={<DownloadSimple size={15} />}
                            >
                                Descargar recibo
                            </Button>
                            <Typography.Text
                                className="h6-regular neutral-1"
                                style={{ marginTop: '22px', marginBottom: '15px' }}
                            >
                                Productos o servicios:
                            </Typography.Text>
                            {items.map((item, index) => (
                                <Row key={index} gutter={20} style={{ padding: 0, margin: 0, width: '100%' }}>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{ marginRight: '15px' }}
                                    >
                                        {item.quantity}
                                    </Typography.Text>
                                    <Typography.Text className="p-base-regular neutral-1" style={{ flex: 1 }}>
                                        {item.name}
                                    </Typography.Text>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        style={{
                                            textAlign: 'end',
                                            width: 'auto',
                                            marginLeft: '10px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {currency?.toUpperCase()} {currencyFormat.format(item.total)}
                                    </Typography.Text>
                                </Row>
                            ))}
                            <Divider style={{ margin: '20px 0' }} />
                            <Row justify="end" style={{ width: '100%' }}>
                                <div className="d-flex flex-column">
                                    {!receipt.hideTaxes && (
                                        <Row justify="space-between">
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'start' }}
                                            >
                                                Subtotal
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()}{' '}
                                                {currencyFormat.format(itemsAmounts?.subtotal)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    {!receipt.hideTaxes && (
                                        <Row justify="space-between" style={{ marginTop: '8px' }}>
                                            <Typography.Text
                                                className="p-base-regular"
                                                style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'start' }}
                                            >
                                                IVA
                                            </Typography.Text>
                                            <Typography.Text className="p-base-regular" style={{ textAlign: 'end' }}>
                                                {currency?.toUpperCase()} {currencyFormat.format(itemsAmounts?.taxes)}
                                            </Typography.Text>
                                        </Row>
                                    )}
                                    <Row justify="space-between" style={{ marginTop: '8px' }}>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{ marginRight: '25px', fontWeight: 'bold', textAlign: 'center' }}
                                        >
                                            Total
                                        </Typography.Text>
                                        <Typography.Text
                                            className="p-base-regular"
                                            style={{ textAlign: 'end', fontWeight: 'bold' }}
                                        >
                                            {currency?.toUpperCase()} {currencyFormat.format(itemsAmounts?.total)}
                                        </Typography.Text>
                                    </Row>
                                </div>
                            </Row>
                            <TryGigstackCard type="autoFactura" hidePBG={receipt.user.brand?.hidePBG} />
                            <Row justify="center" style={{ width: '100%' }}>
                                <PowerByGigPrivacy />
                            </Row>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    )
}

export default GenerateInvoiceV2
