import { Typography, Space, Select } from "antd";
import PropTypes from "prop-types";

function InstallmentsLabel({installmentOption, amount}) {

InstallmentsLabel.propTypes = {
  installmentOption: PropTypes.object.isRequired,
  amount: PropTypes.number.isRequired
};
    return (
        <Space
            direction="horizontal"
            style={{
                width: '100%',
                justifyContent: 'space-between',
            }}
        >
            <Typography className="p-base-regular neutral-1">
                {installmentOption.count === 1 ? `Pago de contado` : `${installmentOption.count} pagos mensuales`}
            </Typography>
            <Typography className="p-base-regular neutral-1">{`$${(amount / installmentOption.count).toFixed(2)}`}</Typography>
        </Space>
    )
}

export const InstallmentsSection = ({
  installments,
  setSelectedPlan,
  selectedPlan,
  amount
}) => {

  InstallmentsSection.propTypes = {
    installments: PropTypes.array.isRequired,
    setSelectedPlan: PropTypes.func.isRequired,
    selectedPlan: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired,
  };

  const handleSelectInstallment = (e) => {
    const dropdownSelection = installments.find((element) => element.count === e.value);
    return setSelectedPlan(dropdownSelection);
  };

  const mapInstallmentsToDropdown = () => {
    return installments.length > 0 &&
      installments[0].count &&
        installments.map((element) => {
            return {
                key: element.count,
                value: element.count,
                label: (InstallmentsLabel({installmentOption: element, amount}))
            }
        })
  }

  const items = mapInstallmentsToDropdown();
  const defaultLabel = (InstallmentsLabel({installmentOption: installments[0], amount}));


  return (
    <Space direction="vertical" style={{padding: "5px"}}>
      <Typography.Text>
        <span
          style={{ lineHeight: "24px" }}
          className="p-base-semi-bold neutral-1"
        >
          Planes disponibles
        </span>
      </Typography.Text>
      <div style={{marginBottom: "10px"}}>
            <Select
              value={
                selectedPlan 
                ? 
                {value: selectedPlan.count, label: (InstallmentsLabel({installmentOption: selectedPlan, amount}))}
                : 
                {value: installments[0].count, label: defaultLabel}}
              defaultValue={{value: installments[0].count, label: defaultLabel}}
              labelInValue
              style={{ width: "100%"}}
              options={items}
              onChange={handleSelectInstallment}
            />
      </div>
      <div>
    <Typography className={`p-small-regular ${installments.length > 1 ? "green-8" : "red-10"}`} >
    {installments.length > 1 ? "Meses sin intereses disponibles" : "Meses sin intereses no disponibles"}
    </Typography>
  </div>
    </Space>
  );
};
