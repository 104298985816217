import { Button, Col, Form, Input, Layout, Row, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import LoadingFullScreen from '../../components/LoadingFullScrren'
import CustomFloatButton from '../../components/FloatButton'
import axios from 'axios'
import { baseUrl } from '../../config/axios'
import { useDispatch } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { LogoHeader } from '../../components/GenerateInvoice'
import PrivacyBanner from '../../components/NoticeOfPrivacy'

export const PaymentLinks = () => {
    const { id } = useParams()
    const [loading, setloading] = useState(true)
    const [loadingPayment, setloadingPayment] = useState(false)
    const [error, seterror] = useState(null)
    const [paymentLinkData, setpaymentLinkData] = useState(null)
    const dispatch = useDispatch()

    const retrievePaymentLinkData = async () => {
        try {
            const paymentData = await axios
                .create({
                    baseURL: baseUrl,
                    headers: {
                        'cache-control': 'no-cache',
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                    },
                })
                .post('paymentLinksApp/v1/retrievePublicData', {
                    id,
                })

            dispatch(setData({ item: 'brand', data: paymentData?.data?.brand }))
            setpaymentLinkData(paymentData?.data)
            setloading(false)
        } catch (error) {
            setloading(false)
            seterror(error?.response?.data?.message ?? 'Ha ocurrido un error, por favor intenta de nuevo')
            console.log(error)
        }
    }

    useEffect(() => {
        if (id) {
            retrievePaymentLinkData()
        }
    }, [id])

    const onFinish = async (values) => {
        setloadingPayment(true)

        const resp = await axios
            .create({
                baseURL: baseUrl,
                headers: {
                    'cache-control': 'no-cache',
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
            })
            .post('paymentLinksApp/v1/createPaymentRequestFromEmail', {
                paymentLinkId: id,
                email: values.email,
                name: values.name || values.email,
                phone: values.phone || '',
            })

        window.location.href = `/pay/${resp.data.token}`

        setloadingPayment(false)

        // await new Promise((resolve) => setTimeout(resolve, 3000))
        // setloadingPayment(false)
        // window.location.href = `/pay/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXF1ZXN0ZXIiOiJaSE5rQUVycll6U2dQc1Job3NzZXd0eVdITEUzIiwicGF5bWVudCI6InBheW1lbnRfV0wxa0RnSkpYOE1TVlhJIiwiaWF0IjoxNzE1Mzc2NjIyfQ.dPZknMduL5ANjjmGu2CsNFds16d9f1Nz3RCiFF81MIY`
    }

    const GetClientForm = () => {
        return (
            <Row style={{ width: '100%' }} justify="center">
                <Col xs={23} lg={12}>
                    <LogoHeader {...(paymentLinkData?.brand ?? {})} livemode={paymentLinkData.livemode ?? true} />
                    <Form layout="vertical" style={{ margin: '80px' }} onFinish={onFinish}>
                        <Form.Item
                            label={paymentLinkData?.paymentPortalDefaults?.nameField ?? "Nombre"}
                            name="name"
                            rules={[{ required: true, message: 'Por favor ingresa tu nombre' }]}
                        >
                            <Input placeholder="Nombre completo" />
                        </Form.Item>
                        <Form.Item
                            label="Correo electrónico"
                            name="email"
                            rules={[{ required: true, message: 'Por favor ingresa tu correo electrónico' }]}
                        >
                            <Input placeholder="correo@dominio.com" />
                        </Form.Item>
                        <Form.Item
                            label="Número de teléfono"
                            name="phone"
                            rules={[{ required: true, message: 'Por favor ingresa tu número de teléfono' }]}
                        >
                            <Input placeholder="+52....." />
                        </Form.Item>
                        <Row style={{ marginTop: '40px' }}>
                            <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loadingPayment}>
                                Continuar
                            </Button>
                        </Row>
                    </Form>
                    <PrivacyBanner />
                </Col>
            </Row>
        )
    }

    const ToRender = () => {
        if (loading) {
            return <LoadingFullScreen loading={true} />
        }

        if (error) {
            return <Typography.Text>{error}</Typography.Text>
        }

        return <GetClientForm />
    }

    return (
        <Layout style={{ padding: 0, margin: 0 }}>
            <Layout.Content
                style={{
                    minHeight: '85vh',
                    width: '100%',
                    backgroundColor: '#F7F9FB',
                    padding: '10px 0px',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ToRender />
            </Layout.Content>
            <CustomFloatButton
                type={paymentLinkData?.brand?.supportPhone ? 'whatsapp' : 'mail'}
                phone={paymentLinkData?.brand?.supportPhone}
                mail={paymentLinkData?.brand?.supportEmail ?? 'ayuda@gigstack.io'}
            />
        </Layout>
    )
}
