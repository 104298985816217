import { Alert, Button, Popconfirm, Row, Typography, message } from 'antd'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageEdit from 'filepond-plugin-image-edit'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import { UploadSimple } from '@phosphor-icons/react/dist/ssr'
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import 'filepond/dist/filepond.css'
import { useState } from 'react'
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { baseUrl } from '../../config/axios'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { setPaymentInReview } from '../../context/payments/paymentsSlice'

registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageEdit,
    FilePondPluginFileValidateSize,
    FilePondPluginFileValidateType,
)

export const InfoData = ({ title, value }) => {
    return (
        <div className="d-flex" style={{ alignItems: 'center', marginTop: '15px' }}>
            <div>
                <Typography.Text className="p-base-bold">{title}</Typography.Text>
            </div>
            <div style={{ marginLeft: 'auto' }}>
                <Typography.Text
                    className="p-base-regular neutral-3"
                    style={{ textAlign: 'right' }}
                    copyable={{ text: value }}
                >
                    {value}
                </Typography.Text>
            </div>
        </div>
    )
}

const BankContainer = ({ bank, paymentData, primaryColor, token }) => {
    const [files, setFiles] = useState([])
    const [url, seturl] = useState(null)
    const [meta, setmeta] = useState('')
    const [error, seterror] = useState(null)
    const [loading, setloading] = useState(false)
    const dispatch = useDispatch()

    // const [transferComplete, settransferComplete] = useState(false)
    const { paymentInReview } = useSelector((state) => state.payments)

    const storage = getStorage()

    const RequestReview = async (paidIn = 'bank') => {
        setloading(true)
        try {
            if (paymentData.bank?.voucherRequired && !url) {
                message.error('Debes adjuntar un comprobante de pago')
                setloading(false)
                return
            }
            await axios
                .create({
                    baseURL: baseUrl,
                    headers: {
                        'cache-control': 'no-cache',
                        'Content-Type': 'application/json',
                        accept: 'application/json',
                        authorization: `Bearer ${token}`,
                    },
                })
                .post('resolvePublicPayment', {
                    type: 'review_payment_transfer',
                    paidIn: 'bank',
                    voucher: url || null,
                    voucerMeta: meta || null,
                })

            // console.log("🚀 ~ file: BankContainer.js:44 ~ RequestReview ~ data:", data)

            // settransferComplete(true)
            dispatch(setPaymentInReview(true))
            setloading(false)
        } catch (error) {
            // console.log("🚀 ~ file: BankContainer.js:56 ~ RequestReview ~ error:", error)
            // settransferComplete(false)
            dispatch(setPaymentInReview(false))
            setloading(false)
        }
    }

    const server = {
        instantUpload: false,
        load: (source) => {
            // console.log(source);
        },
        mounted: (source) => {
            // console.log(source);
        },
        process: (fileName, file, metadata, load, error, progress, abort) => {
            //EXTRACT FILE END EXTENSION
            const ext = file.name.split('.').pop() ?? 'jpg'
            const path = `paymentVouchers/${paymentData.id}.${ext}`
            const reference = ref(storage, path)
            const uploadTask = uploadBytesResumable(reference, file, {
                contentType: file.type,
            })
            // console.log(file);
            uploadTask.on(
                'state_changed',
                (snap) => {
                    progress(true, snap.bytesTransferred, snap.totalBytes)
                },
                (err) => {
                    error(err.message)
                    seterror(err.message)
                    // LogEvent('error', { ...err })
                },
                async () => {
                    try {
                        load(fileName)
                        const url = await getDownloadURL(reference)
                        setmeta({
                            extension: ext,
                            path,
                        })
                        // console.log({
                        //     extension: ext,
                        //     path
                        // });
                        // console.log(url);
                        seturl(url)
                        // LogEvent('image_uploaded')
                    } catch (error) {
                        seterror(error.message)
                        message.error('Ocurrió un error al subir la imagen')
                        // LogEvent('error', { ...error })
                    }
                },
            )
        },
        revert: (id, load) => {
            // console.log(id);
            const reference = ref(storage, `paymentVouchers/${paymentData.id}.${meta?.extension ?? 'jpg'}`)
            deleteObject(reference)
                .then(() => {
                    message.info('Captura eliminada')
                    // load(id)
                    // seturl(null)
                    // setmeta(null)
                    // seterror(null)
                })
                .catch((err) => {
                    load(id)
                    // console.log(err)
                    // LogEvent('error', { err })
                    seterror(err)
                })
        },
    }

    // console.log(paymentData.brand);

    console.log(paymentData)
    const TransactionCompleted = () => {
        return (
            <div className="d-flex flex-column" style={{ marginTop: '30px', alignItems: 'center' }}>
                <Typography.Title level={5} style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>
                    ¡Gracias! <br /> Estamos revisando tu pago.
                </Typography.Title>
            </div>
        )
    }
    return paymentInReview ? (
        <div style={{ marginTop: '25px' }}>
            <TransactionCompleted />
        </div>
    ) : (
        <div className="d-flex flex-column" style={{ marginTop: '25px' }}>
            {paymentData?.customBankAlert && (
                <Alert
                    message={paymentData?.customBankAlert ?? ''}
                    type="warning"
                    showIcon
                    style={{ margin: '0px 0px 10px 0px' }}
                />
            )}
            <Typography.Text className="h6-bold neutral-1">Información para la transferencia</Typography.Text>
            <InfoData
                title="Banco:"
                value={
                    <>
                        {bank?.bank} <span>{bank?.tag}</span>
                    </>
                }
            />
            <InfoData title="Beneficiario:" value={bank?.holder_name} />
            {bank?.clabe && <InfoData title="CLABE:" value={bank?.clabe} />}
            {bank?.swift && <InfoData title="SWIFT:" value={bank?.swift} />}
            {bank?.bic && <InfoData title="BIC:" value={bank?.bic} />}
            {bank?.iban && <InfoData title="IBAN:" value={bank?.iban} />}
            <InfoData title="Concepto:" value={paymentData?.conceptReference} />
            {/* {paymentData?.clabe && <InfoData title="CLABE Interbancaria" value={paymentData?.clabe} />} */}
            {paymentData?.bank?.help && (
                <Typography.Paragraph style={{ fontSize: '12px', margin: '0px', marginTop: '20px' }}>
                    {paymentData.bank?.help}
                </Typography.Paragraph>
            )}
            {!paymentData?.hideUploadTransferButton ? (
                <div style={{ margin: '30px 0px' }} className="d-flex flex-column">
                    <Typography.Text style={{ marginBottom: '10px', marginTop: '10px' }} className="h6-bold">
                        {' '}
                        Adjunta tu comprobante y envíalo
                    </Typography.Text>
                    <FilePond
                        files={files}
                        allowImagePreview={false}
                        onupdatefiles={setFiles}
                        allowFileTypeValidation={true}
                        allowMultiple={false}
                        maxFiles={1}
                        server={server}
                        acceptedFileTypes={['image/*']}
                        labelFileProcessing="Procesando..."
                        labelFileProcessingComplete="Completado..."
                        labelFileProcessingAborted="Cancelado..."
                        labelTapToCancel="Cancelar"
                        labelTapToRetry="Reintentar"
                        labelTapToUndo="Deshacer"
                        labelButtonRemoveItem="Eliminar"
                        name="files"
                        allowFileSizeValidation={true}
                        maxFileSize="5MB"
                        maxTotalFileSize="5MB"
                        allowImageEdit={false}
                        labelMaxFileSizeExceeded="La imágen es demasiado grande"
                        labelMaxFileSize="Tamaño máximo de la imágen: {filesize}"
                        labelMaxTotalFileSizeExceeded="El tamaño total de las imágenes supera el límite"
                        labelMaxTotalFileSize="Tamaño máximo total de las imágenes: {filesize}"
                        labelIdle={`
                <div class="d-flex flex-column">
                    <div style="text-align:center; margin-top:15px">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M224,144v64a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V144a8,8,0,0,1,16,0v56H208V144a8,8,0,0,1,16,0ZM93.66,77.66,120,51.31V144a8,8,0,0,0,16,0V51.31l26.34,26.35a8,8,0,0,0,11.32-11.32l-40-40a8,8,0,0,0-11.32,0l-40,40A8,8,0,0,0,93.66,77.66Z"></path></svg>
                    </div>
                    <p class="p-small-regular neutral-1" style="margin-bottom:0px;"">Haz Click para subirlo o arrastrarlo</p>
                    <p class="p-small-regular neutral-1">png, jpg o png </p>
                </div>`}
                    />

                    {error && (
                        <Row>
                            <Typography.Text type="warning">{error}</Typography.Text>
                        </Row>
                    )}

                    <Row justify="center" style={{ marginTop: '25px' }}>
                        <Popconfirm
                            title="Enviar para revisión."
                            icon={<AiOutlineInfoCircle style={{ color: primaryColor }} />}
                            okText="Enviar"
                            okButtonProps={{ disabled: loading, style: { backgroundColor: primaryColor } }}
                            cancelText="Atrás"
                            onConfirm={() => RequestReview('bank')}
                        >
                            <Button
                                disabled={paymentData.bank?.voucherRequired && !url}
                                className={paymentData.bank?.voucherRequired ? 'w-100' : 'w-100 btn-shadow'}
                                style={{
                                    color: 'white',
                                    backgroundColor:
                                        paymentData.bank?.voucherRequired && !url ? '#cecece' : primaryColor,
                                    border: 'none',
                                }}
                                loading={loading}
                            >
                                {paymentData.bank?.voucherRequired && !url
                                    ? 'Añade tu comprobante de transferencia'
                                    : 'Enviar comprobante'}
                            </Button>
                        </Popconfirm>
                    </Row>
                </div>
            ) : (
                <Row justify="center" style={{ marginTop: '25px' }}>
                    <Button onClick={() => dispatch(setPaymentInReview(true))} type="primary">
                        Ya realicé el pago
                    </Button>
                </Row>
            )}
        </div>
    )
}

export default BankContainer
