import { useStripe } from '@stripe/react-stripe-js'
import { Button, Row, Space, Spin, Typography } from 'antd'
import { memo, useEffect, useState } from 'react'
import { InfoData } from '../BankContainer'

const CustomerBalanceSection = (props) => {
    const stripe = useStripe()
    const [bankInstructions, setBankInstructions] = useState(null)
    const [loading, setloading] = useState(false)

    const confirmCustomerBalancePayment = async () => {
        if (!stripe || bankInstructions) return
        setloading(true)
        const resp = await stripe.confirmCustomerBalancePayment(props.paymentData.client_secret)
        if (resp.error) {
            console.log(resp.error)
            return
        } else {
            setBankInstructions(resp?.paymentIntent?.next_action?.display_bank_transfer_instructions)
        }
        setloading(false)
    }

    const confirm = async () => {
        if (!bankInstructions) {
            await new Promise((resolve) => setTimeout(resolve, 2000))
            confirmCustomerBalancePayment()
        }
    }

    // if (loading) return (<Row align={"middle"} justify={"center"}>
    //     <Spin />
    // </Row>)

    return !bankInstructions ? (
        <Space direction="vertical" align="center" style={{ width: '100%' }}>
            <Typography.Text style={{ fontSize: '14px', fontWeight: 'bold' }}>Confirmación de pago</Typography.Text>
            <Button loading={loading} onClick={confirm}>
                Confirmo que deseo pagar con transferencia
            </Button>
        </Space>
    ) : (
        <>
            <Typography.Text style={{ fontSize: '14px', fontWeight: 'bold' }}>
                Información para transferencia
            </Typography.Text>
            <InfoData
                title="Banco"
                value={
                    <>
                        {(bankInstructions?.financial_addresses ?? [])?.[0]?.spei?.bank_name}{' '}
                        <span>{bankInstructions?.tag}</span>
                    </>
                }
            />
            <InfoData title="CLABE" value={(bankInstructions?.financial_addresses ?? [])?.[0]?.spei?.clabe} />
            <InfoData title="Referencia" value={bankInstructions?.reference} />
        </>
    )
}

export default CustomerBalanceSection
