import { Typography } from 'antd'

import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'

// import loaderGif from '../../assets/gifs/loadingGif.gif'

function PaymentWaitingForReview({ brand }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '15px 15px',
            }}
        >
            {/* <img src={loaderGif} alt="Cargando..." width={50} height={50} /> */}
            <AiOutlineCheckCircle style={{ color: brand.primaryColor, fontSize: '30px' }} />
            <Typography.Text type="secondary" style={{ fontSize: '13px', marginTop: '15px' }}>
                Nos encontramos revisando el pago, enviaremos un correo de confirmado
            </Typography.Text>
        </div>
    )
}

export default PaymentWaitingForReview
