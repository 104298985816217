import React, { memo, useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'

const StripeContainer = memo((props) => {
    let stripePromise = null
    if (props.paymentData?.publicApiKey) {
        stripePromise = loadStripe(props.paymentData.publicApiKey)
    } else {
        stripePromise = loadStripe(
            props.livemode
                ? 'pk_live_PpYLkGCXtQ621ulE38swATmt00zZ54hut1'
                : 'pk_test_2KbH9OHirQCC3WKoDXb8tqid00PXtTZIBX',
            { stripeAccount: props.stripeAccount },
        )
    }
    // var stripePromise;
    const [loading, setloading] = useState(true)

    useEffect(() => {
        if (stripePromise) {
            setloading(false)
        }
    }, [stripePromise])

    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm
                succeededPayment={props.succeededPayment}
                paymentData={props.paymentData}
                oxxo={props.oxxo ?? null}
                customerBalance={props.customerBalance}
            />
        </Elements>
    )
})

StripeContainer.displayName = 'StripeContainer'

export default StripeContainer
