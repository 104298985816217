import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Spin, message, Typography } from 'antd'
import { axiosReq } from '../../config/axios'
import PrivacyBanner from '../../components/NoticeOfPrivacy'
import { BiCheckCircle } from 'react-icons/bi'

const { Content } = Layout

const GetInvoice = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(null)

    const DownloadInvoice = async ({ id, rfc }) => {
        try {
            setLoading(true)
            const response = await axiosReq.post('resolveReceiptDataSeats', {
                id,
                type: 'getInvoice',
                rfc,
            })
            const pdfData = response.data
            const blob = new Blob([pdfData], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'invoice.pdf'
            link.click()
            setLoading(false)
            setData('completed')
            message.success('Descarga completada', 5)
            await new Promise((resolve) => setTimeout(resolve, 2000))
            window.close()
        } catch (error) {
            console.error('Error downloading PDF:', error)
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const id = queryParams.get('id')
        const rfc = queryParams.get('rfc')
        console.log(id)
        if (!loading && !data) {
            DownloadInvoice({ id, rfc })
        }
        // Do something with the id
    }, [])

    return (
        <Layout>
            <Content style={{ height: '100vh' }}>
                <Row style={{ height: '100%' }}>
                    <Col span={24} xs={24} style={{ height: '100%' }}>
                        {/* Add your content here */}
                        <Row justify="center" align="middle" style={{ height: '100%' }}>
                            {data === 'completed' ? (
                                <div className="d-flex flex-column">
                                    <Row justify="center">
                                        <BiCheckCircle
                                            style={{ fontSize: '50px', color: '#00b96b', textAlign: 'center' }}
                                        />
                                    </Row>
                                    <Typography.Text
                                        type="secondary"
                                        style={{ textAlign: 'center', marginBottom: '50px' }}
                                    >
                                        El archivo se ha descargado con éxito, puedes cerrar la ventana.
                                    </Typography.Text>
                                    <PrivacyBanner />
                                </div>
                            ) : (
                                <Spin />
                            )}
                        </Row>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

export default GetInvoice
