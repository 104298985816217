import { createSlice } from '@reduxjs/toolkit'

export const dataSlice = createSlice({
    name: 'data',
    initialState: {},
    reducers: {
        setData: (state, action) => {
            state[action.payload.item] = action.payload.data
        },
        pushNewData: (state, action) => {
            state[action.payload.item].push(action.payload.data)
        },
        deleteData: (state, action) => {
            state[action.payload.item] = state[action.payload.item].filter((item) => item.id !== action.payload.id)
        },
        updateData: (state, action) => {
            state[action.payload.item] = state[action.payload.item].map((item) => {
                if (item.id === action.payload.data.id) {
                    return action.payload.data
                }
                return item
            })
        },
        setElementSelected: (state, action) => {
            state[action.payload.item] = action.payload.data
        },
    },
})

export const { setData, pushNewData, deleteData, updateData, setElementSelected } = dataSlice.actions

export default dataSlice.reducer
