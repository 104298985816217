import { Layout, Typography, message, notification, ConfigProvider } from 'antd'
import React, { useEffect, useState } from 'react'
import { axiosReq } from '../../config/axios'
import ReceiptCompleted from '../../components/ReceiptCompleted'
import GenerateInvoice from '../../components/GenerateInvoice'
import LoadingFullScreen from '../../components/LoadingFullScrren'
import CustomFloatButton from '../../components/FloatButton'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { usePostHog } from 'posthog-js/react'
import GenerateInvoiceV2 from '../../components/GenerateInvoiceV2'
import { useLocation } from 'react-router-dom'
import UntimedInvoiceMessage from '../../components/UntimedInvoiceMessage'

function Autofactura({ remoteConfig }) {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const [data, setDataS] = useState(null)
    const [loading, setLoading] = useState(true)
    const [customError, setcustomError] = useState(null)
    const { brand } = useSelector((state) => state.data)

    const APIRequest = async ({ receiptId, invoiceId }) => {
        let messageLoading = null

        try {
            setDataS({})
            messageLoading = message.loading('Cargando datos de la factura', 0)

            const data = await axiosReq.post('resolveReceiptDataSeats', {
                id: invoiceId || receiptId,
                type: invoiceId ? 'getInvoice' : null,
            })

            //CLOSE MESSAGEGE LOADING
            messageLoading()
            dispatch(setData({ item: 'brand', data: data?.data?.brand }))
            setDataS({
                ...data.data,
                isInvoice: invoiceId ? true : false,
            })

            posthog.capture('receipt_data', {
                team: data?.data?.brand?.alias ?? 'unknown',
                id: receiptId || '',
                invoiceId: invoiceId || '',
            })

            setLoading(false)
        } catch (error) {
            console.log(error.response?.data)
            setDataS(error.response?.data)
            if (error.response?.data?.status === 'completed') {
                setcustomError(null)
            } else {
                setcustomError(error.response?.data.message)
                notification.error({
                    duration: 0,
                    message: 'Error al cargar los datos de la factura',
                    description: error.response?.data?.message,
                })
            }
            setLoading(false)
            messageLoading()
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const id = params.get('id')
        const invoiceId = params.get('invoiceId')

        if (!id && !invoiceId) {
            message.info('No se encontró el ID de la factura', 10)
        }

        if (!data && (id || invoiceId)) {
            APIRequest({ receiptId: id, invoiceId })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: brand?.primaryColor ?? '#00b96b',
                    colorLink: brand?.primaryColor ?? '#00b96b',
                    colorText: '#333',
                    colorTextSecondary: '#333',
                },
                components: {
                    Input: {
                        controlHeight: 40,
                    },
                },
            }}
        >
            <Layout style={{ padding: 0, margin: 0 }}>
                <Layout.Content
                    style={{
                        minHeight: '100vh',
                        width: '100%',
                        backgroundColor: '#F1F5F9',
                        padding: 0,
                        margin: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {customError ? (
                        <div style={{}} className="d-flex flex-column">
                            <Typography.Title level={3} style={{ textAlign: 'center' }}>
                                Error al cargar los datos de la factura
                            </Typography.Title>
                            <Typography.Text style={{ textAlign: 'center', fontSize: '14px' }}>
                                {customError}
                            </Typography.Text>
                            <Typography.Text
                                style={{ textAlign: 'center', fontSize: '12px', marginTop: '20px' }}
                                type="secondary"
                            >
                                Es posible que el recibo haya sido eliminado
                            </Typography.Text>
                        </div>
                    ) : loading ? (
                        <LoadingFullScreen />
                    ) : (
                        data?.untimedInvoice ? (
                            <UntimedInvoiceMessage />
                        ) :
                        (data?.status === 'completed' || data?.isInvoice) && (
                            <ReceiptCompleted
                                isB64Invoice={data?.isInvoice}
                                invoiceId={data?.invoice}
                                invoiceFiles={data?.invoiceFiles}
                                title="Recibo completo"
                                message="Para descargar la factura ingresa tu RFC"
                                {...data?.itemsAmounts}
                                {...data?.brand}
                                alias={data?.brand?.alias}
                            />
                        )
                    )}
                    {/* {data?.status === 'pending' ?
                        // posthog.isFeatureEnabled('new_invoice_page') ? <GenerateInvoiceV2 receipt={data} /> : <GenerateInvoice receipt={data} /> : <></>

                    } */}

                    {/* {data?.status === 'pending' && <GenerateInvoice receipt={data} />} */}
                    {data?.status === 'pending' && <GenerateInvoiceV2 receipt={data} />}
                </Layout.Content>
                {/* <Layout.Footer style={{ backgroundColor: '#F7F9FB' }}>
                    <CustomFloatButton
                        type={data?.supportPhone ? 'whatsapp' : 'mail'}
                        phone={data?.supportPhone}
                        mail={data?.supportEmail}
                    />
                </Layout.Footer> */}
            </Layout>
        </ConfigProvider>
    )
}

export default Autofactura
