import { Routes, Route } from 'react-router-dom'
import './App.css'
import LoadingFullScreen from './components/LoadingFullScrren'

import Autofactura from './app/pages/Autofactura'
import Pay from './app/pages/Pay'
import ReactGA from 'react-ga'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import GetInvoice from './app/pages/GetInvoice'
import { useEffect } from 'react'
import { PaymentLinks } from './app/pages/PaymentLinks'
ReactGA.initialize([
    {
        trackingId: 'G-MWNZRWN70Z',
    },
])

function App() {
    useEffect(() => {
        posthog.init('phc_ud0yyxYqNQb05H9wNaTFcFcLPSJfLHzun1E9jIvV74f', {
            api_host: 'https://app.posthog.com',
            opt_in_site_apps: true,
        })
    }, [])

    return (
        <PostHogProvider client={posthog}>
            <Routes>
                <Route path="/" element={<LoadingFullScreen />} />
                <Route path="/pay/:id" element={<Pay />} />
                <Route path="/paymentLinks/:id" element={<PaymentLinks />} />
                <Route path="/autofactura" element={<Autofactura />} />
                <Route path="/invoice" element={<GetInvoice />} />
            </Routes>
        </PostHogProvider>
    )
}

export default App
