import axios from 'axios'

export const axiosReq = axios.create({
    baseURL: `${process.env.REACT_APP_INTERNAL_API_URL}/`,
    Headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        accept: 'application/json',
        Authorization: `Bearer `,
    },
})

export const baseUrl = `${process.env.REACT_APP_INTERNAL_API_URL}/`
