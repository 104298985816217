import { createSlice } from '@reduxjs/toolkit'

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState: {
        paymentInReview: false,
        waittingForOxxo: false,
        paymentCompletedContext: false,
        confirmedMethod: null,
    },
    reducers: {
        setPaymentInReview: (state, action) => {
            state.paymentInReview = action.payload
        },
        setWaittingForOxxo: (state, action) => {
            state.waittingForOxxo = action.payload
        },
        setPaymentCompleted: (state, action) => {
            state.paymentCompletedContext = action.payload
        },
        setConfirmedMethod: (state, action) => {
            state.confirmedMethod = action.payload
        },  
    },
})

export const { setPaymentInReview, setWaittingForOxxo, setPaymentCompleted, setSelectedPaymentMethod, setConfirmedMethod } = paymentsSlice.actions

export default paymentsSlice.reducer
