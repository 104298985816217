import { configureStore } from '@reduxjs/toolkit'
import paymentsReducer from '../context/payments/paymentsSlice'
import dataSlice from '../context/dataSlice'

const store = configureStore({
    reducer: {
        payments: paymentsReducer,
        data: dataSlice,
    },
})

export default store
