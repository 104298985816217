import { CheckCircle } from '@phosphor-icons/react'
import { Typography } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

function PaymentCompleted() {

    const { brand } = useSelector((state) => state.data)

    return (
        <div className="d-flex flex-column" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <CheckCircle size={35} color={brand?.primaryColor ?? '#00b96b'} style={{ margin: '15px' }} />
            <Typography.Text className="h6-bold" style={{ textAlign: 'center' }}>
                ¡Gracias! <br />
            </Typography.Text>
            <Typography.Text style={{ textAlign: 'center' }}>Hemos recibido el pago.</Typography.Text>
        </div>
    )
}

export default PaymentCompleted
