import { Typography, Row, Col, Image, Divider } from 'antd'
import { motion } from 'framer-motion'
import { VscLoading } from 'react-icons/vsc'
import { DownloadSimple } from '@phosphor-icons/react'
import { useState } from 'react'
import PowerByGigPrivacy from './PowerdByGigPrivacy'
import TryGigstackCard from './TryGigstackCard'

const PaymentCompleted = ({ brand, payment }) => {
    const { logo, alias, primaryColor } = brand
    const { customerPortalId } = payment

    return (
        <Row>
            <Col xs={24}>
                <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                >
                    <Row style={{ width: '100%' }} gutter={{ xs: 5, md: 10, lg: 12 }} justify="center">
                        <Col xs={24}>
                            <Row justify="center">
                                <Image
                                    src={logo}
                                    alt={alias}
                                    width={80}
                                    preview={false}
                                    // hide on xs
                                    style={{
                                        display: window.innerWidth < 768 ? 'none' : 'block',
                                    }}
                                />
                            </Row>
                            <Row justify="center" style={{ marginTop: '15px' }}>
                                <Typography.Title level={4} className="h5-bold neutral-1">
                                    Gracias por tu pago
                                </Typography.Title>
                            </Row>
                            {customerPortalId ? (
                                <div
                                    className="d-flex flex-row flex-center btn-shadow"
                                    style={{
                                        width: '100%',
                                        padding: '15px',
                                        borderRadius: '10px',
                                        backgroundColor: primaryColor,
                                        cursor: 'pointer',
                                        height: '40px',
                                        marginTop: '15px',
                                    }}
                                    onClick={() => {
                                        window.open(`https://portal.gigstack.pro/${customerPortalId}`, '_blank')
                                    }}
                                >
                                    <Row align="middle">
                                        <Typography.Text style={{ color: 'white' }}>
                                            Ir al portal de clientes
                                        </Typography.Text>
                                        <DownloadSimple
                                            style={{ marginLeft: '14px', color: 'white', fontSize: '18px' }}
                                        />
                                    </Row>
                                </div>
                            ) : (
                                <Row justify="center" style={{ marginTop: '15px' }}>
                                    <Typography.Text
                                        className="p-base-regular neutral-1"
                                        copyable={{ text: payment?.id }}
                                    >
                                        <span className="p-base-bold">ID de tu pago: </span>
                                        {payment?.id}
                                    </Typography.Text>
                                </Row>
                            )}
                            <Divider />
                            <Row justify="center">
                                <div style={{ margin: 'auto' }}>
                                    <TryGigstackCard type="pago" />
                                </div>
                                <PowerByGigPrivacy />
                            </Row>
                        </Col>
                    </Row>
                </motion.div>
            </Col>
        </Row>
    )
}

export default PaymentCompleted
