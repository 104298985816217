import axios from "axios"
import { baseUrl } from "../config/axios"
import { message } from "antd"

export const formatter = new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
})

export const paymentMethodInfo = (id) => {
    let info = {
        image: '',
        text: '',
        useDiscount: false,
    }
    switch (id) {
        case 'oxxo':
            info.image = 'https://js.stripe.com/v3/fingerprinted/img/oxxo-96b6ab36d23607973cb466bec56d187b.svg'
            info.text = 'OXXO'
            break
        case 'card':
            info.image = 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/Cards2.svg'
            info.text = 'Tarjeta de crédito o débito'
            break
        case 'bank':
            info.image = 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png'
            info.text = 'Transferencia bancaria'
            info.useDiscount = true
            break
        case 'customer_balance':
            info.image = 'https://pro-gigstack.s3.us-east-2.amazonaws.com/icons/+bank.png'
            info.text = 'Transferencia bancaria (Stripe)'
            break
        default:
            break
    }
    return info
}

export const handleStripeCardErrorCodesToSpanish = (code) => {
    switch (code) {
        case 'incomplete_number':
            return 'El número de tarjeta es inválido'
        case 'incomplete_expiry':
            return 'La fecha de expiración es inválida'
        case 'incomplete_cvc':
            return 'El código de seguridad es inválido'
        case 'incomplete_zip':
            return 'El código postal es inválido'
        case 'invalid_number':
            return 'El número de tarjeta es inválido'
        case 'invalid_expiry_month':
            return 'El mes de expiración es inválido'
        case 'invalid_expiry_year':
            return 'El año de expiración es inválido'
        case 'invalid_cvc':
            return 'El código de seguridad es inválido'
        case 'fraudulent':
            return 'La tarjeta ha sido marcada como fraudulenta'
        case 'incorrect_number':
            return 'El número de tarjeta es incorrecto'
        case 'expired_card':
            return 'La tarjeta ha expirado'
        case 'incorrect_cvc':
            return 'El código de seguridad es incorrecto'
        case 'incorrect_zip':
            return 'El código postal es incorrecto'
        case 'card_declined':
            return 'La tarjeta ha sido declinada'
        case 'missing':
            return 'No se ha encontrado la tarjeta'
        case 'processing_error':
            return 'Ocurrió un error al procesar la tarjeta'

        default:
            return 'No ha sido posible hacer el cargo'
    }
}

// export const getItemsAmounts = ({ items = [], useDiscount = false }) => {
//     var subtotal = 0;
//     var taxes = 0;
//     var taxesIncluded = 0;
//     var retentions = 0;
//     var canSend = true;
//     var feeAdded = 0
//     var masterDiscount = 0;
//     // var haveIVA = false;

//     items.forEach((s) => {

//         var times = 1
//         var serviceTotal = 0;
//         var paymentType = s?.paymentType ? s?.paymentType.value : 'fixed'
//         masterDiscount = masterDiscount + (s.discount ?? 0)

//         if (!s.product_key) {
//             canSend = false;
//         }
//         if (s.quantity) {
//             times = s.quantity
//         }
//         if (paymentType === 'hour') {
//             serviceTotal = ((parseInt(s.hours, 10) * parseFloat(s.total)) * times)
//             subtotal = subtotal + ((parseInt(s.hours, 10) * parseFloat(s.total)) * times)
//             if (s.feeAdded) {
//                 feeAdded += (s.feeAdded * (parseInt(s.hours, 10)) * times)
//             }
//         } else {
//             serviceTotal = (parseFloat(s.total) * times)
//             subtotal = subtotal + (parseFloat(s.total) * times)
//             if (s.feeAdded) {
//                 feeAdded += s.feeAdded * times
//             }
//         }
//         if (s.taxes) {
//             var nFeeAdded = useDiscount ? (s.feeAdded || 0) : 0
//             var d2 = s.discount ?? 0
//             s.taxes.forEach((t) => {
//                 if (t.type === 'IVA') {
//                     // haveIVA = true;
//                 }
//                 if (!t.withholding) {
//                     if (t.inclusive) {
//                         taxesIncluded = taxesIncluded + ((serviceTotal - (nFeeAdded + d2)) - ((serviceTotal - (nFeeAdded + d2)) / (parseFloat(t.rate) + 1)))
//                     } else {
//                         taxes = taxes + (serviceTotal - (nFeeAdded + d2)) * (parseFloat(t.rate))
//                     }

//                 } else {
//                     retentions = retentions + (serviceTotal - (nFeeAdded + d2)) * (parseFloat(t.rate))
//                 }
//             })
//         }
//     })

//     return {
//         total: Number(parseFloat(((subtotal + taxes)) - retentions, subtotal).toFixed(2)) - masterDiscount,
//         taxes: taxes + taxesIncluded,
//         retentions,
//         feeAdded: feeAdded,
//         canSend,
//         feeAddedString: formatter.format(feeAdded),
//         totalWithoutFeeString: formatter.format((((subtotal + taxes) - taxesIncluded) - retentions) - feeAdded),
//         totalWithoutFee: (((subtotal + taxes) - taxesIncluded) - retentions) - feeAdded,
//         totalString: formatter.format(Number(parseFloat(((subtotal + taxes)) - retentions, subtotal).toFixed(2)) - masterDiscount),
//         subtotalString: formatter.format(subtotal - taxesIncluded),
//         taxesString: formatter.format(taxes + taxesIncluded),
//         retentionsString: formatter.format(retentions),
//         masterDiscount,
//         taxesIncluded,
//         masterDiscountString: formatter.format(masterDiscount),
//     }
// }
export const getItemsAmounts = ({ items = [], useDiscount = false }) => {
    var subtotal = 0
    var taxes = 0
    var taxesIncluded = 0
    var retentions = 0
    var canSend = true
    var feeAdded = 0
    var masterDiscount = 0
    // var haveIVA = false;

    items.forEach((s) => {
        var times = 1
        var serviceTotal = 0
        var paymentType = s?.paymentType ? s?.paymentType.value : 'fixed'
        let discount = s.discount ?? 0
        let taxInclusiveRate = Number(s?.taxes?.find((t) => t?.inclusive)?.rate ?? 0)
        if (s?.taxes?.find((t) => t?.inclusive)) {
            discount = discount / (1 + taxInclusiveRate)
        }

        masterDiscount = masterDiscount + (discount ?? 0)

        if (!s.product_key) {
            canSend = false
        }
        if (s.quantity) {
            times = s.quantity
        }
        if (paymentType === 'hour') {
            serviceTotal = parseInt(s.hours, 10) * parseFloat(s.total) * times
            subtotal = subtotal + parseInt(s.hours, 10) * parseFloat(s.total) * times
            if (s.feeAdded) {
                feeAdded += s.feeAdded * parseInt(s.hours, 10) * times
            }
        } else {
            serviceTotal = parseFloat(s.total) * times
            subtotal = subtotal + (parseFloat(s.total) * times) / (1 + taxInclusiveRate)
            if (s.feeAdded) {
                feeAdded += s.feeAdded * times
            }
        }
        if (s?.taxes || s?.localTaxes) {
            var nFeeAdded = useDiscount ? s.feeAdded || 0 : 0
            var d2 = s.discount ?? 0
            ;(s.taxes ?? []).concat(s?.localTaxes ?? []).forEach((t) => {
                if (t.type === 'IVA') {
                    // haveIVA = true;
                }
                if (!t.withholding) {
                    if (t.inclusive) {
                        taxesIncluded =
                            taxesIncluded +
                            (serviceTotal -
                                (nFeeAdded + d2) -
                                (serviceTotal - (nFeeAdded + d2)) / (parseFloat(t.rate) + 1))
                    } else {
                        taxes = taxes + (serviceTotal - (nFeeAdded + d2)) * parseFloat(t.rate)
                    }
                } else {
                    retentions = retentions + (serviceTotal - (nFeeAdded + d2)) * parseFloat(t.rate)
                }
            })
        }
    })
    masterDiscount = Number((masterDiscount + feeAdded).toFixed(2))

    return {
        total: Number(parseFloat(subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) - masterDiscount,
        taxes: Number((taxes + taxesIncluded).toFixed(2)),
        retentions,
        feeAdded: feeAdded,
        canSend,
        feeAddedString: formatter.format(feeAdded),
        totalWithoutFeeString: formatter.format(subtotal + taxes - taxesIncluded - retentions - feeAdded),
        totalWithoutFee: Number((subtotal + taxes /*- taxesIncluded*/ - retentions - feeAdded).toFixed(2)),
        totalString: formatter.format(
            Number(parseFloat(subtotal + (taxesIncluded + taxes) - retentions).toFixed(2)) - masterDiscount,
        ),
        subtotalString: formatter.format(subtotal /*- taxesIncluded*/),
        subtotal: Number(
            subtotal /*- taxesIncluded*/
                .toFixed(2),
        ),
        taxesString: formatter.format(taxes + taxesIncluded),
        retentionsString: formatter.format(retentions),
        masterDiscount,
        taxesIncluded,
        masterDiscountString: formatter.format(masterDiscount),
    }
}

export const confirmPaymentMethod = async ({confirmedMethod, token}) => {
    try {
        let methodBody = {
            type: 'confirm_method',
            confirmed_method: confirmedMethod,
        }
        await axios
            .create({
                baseURL: baseUrl, 
                headers: {
                    'cache-control': 'no-cache',
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                    authorization: `Bearer ${token}`,
                },
            })
            .post('resolvePublicPayment', methodBody)
    } catch (error) {
        console.log(error);
        message.error('No se ha podido confirmar el método de pago')
    }
}