import React, { useState } from 'react'
import { Form, Row, Space, Typography, Input, Col, Button, Image, message, Card } from 'antd'
import { BsCheckCircle } from 'react-icons/bs'
import { axiosReq } from '../../src/config/axios'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import PrivacyBanner from './NoticeOfPrivacy'

function ReceiptCompleted({
    title = 'Recibo completo',
    message: description = '',
    alias = '',
    primaryColor,
    logo,
    totalString,
    totalWithoutFeeString,
    remoteConfig,
    isB64Invoice = false,
    invoiceFiles,
    invoiceId = null,
}) {
    const [loading, setLoading] = useState(false)
    // const [url, setUrl] = useState(null)
    const [fileLinks, setFileLinks] = useState({
        pdfLink: isB64Invoice ? invoiceFiles?.pdf : null,
        xmlLink: isB64Invoice ? invoiceFiles?.xml : null,
    })
    const [state, setState] = useState({
        rfc: '',
    })

    // const handleFormSubmit = async (v) => {
    //     const params = new URLSearchParams(window.location.search)
    //     const id = params.get('id')
    //     try {
    //         setLoading(true)
    //         const data = await axiosReq.post('resolveReceiptDataSeats', {
    //             id, type: 'retrieve', rfc: v.rfc
    //         })
    //         const url = data.data.url[0];
    //         const a = document.createElement("a");
    //         a.target = '_blank';
    //         a.href = url;
    //         a.download = `${id}-${v.rfc}.pdf`;
    //         setUrl(url)
    //         a.click();
    //         a.remove();
    //         setLoading(false)
    //         console.log(data, v)
    //     } catch (error) {
    //         console.log(error)
    //         setLoading(false)
    //         message.error(error?.response?.data?.message ?? 'Ocurrió un error')
    //     }
    // }
    const handleDownloadInvoice = async (v) => {
        try {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            if (loading) return
            setLoading(true)
            const data = await axiosReq.post('resolveReceiptDataSeats', {
                id,
                type: 'retrieve',
                rfc: v.rfc,
            })
            setState(v)
            setFileLinks({
                pdfLink: data.data.url[0],
                xmlLink: data.data.urlxml[0],
            })
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
            message.error(error?.response?.data?.message ?? 'Ocurrió un error')
        }
    }

    const createDownloadLink = (url, filename) => {
        const a = document.createElement('a')
        a.target = '_blank'
        a.href = url
        a.download = filename
        a.click()
        a.remove()
    }

    // console.log(fileLinks)
    const handleDownloadPdf = () => {
        const { pdfLink } = fileLinks
        if (pdfLink && !isB64Invoice) {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            createDownloadLink(pdfLink, `${id || 'factura'}.pdf`)
        }
        if (pdfLink && isB64Invoice) {
            // convert pdfLink to blob and download, is a base64
            const byteCharacters = atob(pdfLink)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], { type: 'application/pdf' })
            const url = URL.createObjectURL(blob)
            createDownloadLink(url, `${invoiceId || 'factura'}.pdf`)
            message.success('Archivo descargado correctamente', 5)
        }
    }

    const handleDownloadXml = () => {
        const { xmlLink } = fileLinks
        if (xmlLink && !isB64Invoice) {
            const params = new URLSearchParams(window.location.search)
            const id = params.get('id')
            createDownloadLink(xmlLink, `${id || 'factura'}.xml`)
        }
        if (xmlLink && isB64Invoice) {
            // convert pdfLink to blob and download, is a base64
            const byteCharacters = atob(xmlLink)
            const byteNumbers = new Array(byteCharacters.length)
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            const byteArray = new Uint8Array(byteNumbers)
            const blob = new Blob([byteArray], { type: 'application/xml' })
            const url = URL.createObjectURL(blob)
            createDownloadLink(url, `${invoiceId || 'factura'}.xml`)
            message.success('Archivo descardago correctamente', 5)
        }
    }

    //CREATE A CONTSANT TO GET THE SCREEN WID

    return (
        <Row style={{ width: '100%' }}>
            <Col
                xs={23}
                md={18}
                lg={13}
                xl={10}
                xxl={8}
                style={{
                    // top: "50%",
                    // left: "50%",
                    // transform: "translate(-50%, -100%)",
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    display: 'flex',
                    // minWidth: window.innerWidth < 800 ? "50%" : "30%",
                    padding: '20px 20px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    margin: 'auto',
                    boxShadow: '10px 10px 17px -2px rgba(222,222,222,0.75)',
                }}
            >
                <Space direction="vertical" align="center">
                    <div
                        style={{
                            backgroundColor: logo ? 'transparent' : '#E7F3FF',
                            borderRadius: '50%',
                            height: logo ? '100px' : '65px',
                            width: logo ? 'auto' : '65px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '10px',
                        }}
                    >
                        {logo ? (
                            <Image src={logo} width={120} alt={alias} />
                        ) : (
                            <BsCheckCircle style={{ fontSize: '30px', margin: 'auto', color: '#5581EF' }} />
                        )}
                    </div>

                    {!fileLinks.pdfLink && !fileLinks.xmlLink && (
                        <Typography.Title level={5} style={{ marginTop: '50px', padding: '0px' }}>
                            {title}
                        </Typography.Title>
                    )}
                    {!fileLinks.pdfLink && !fileLinks.xmlLink && (
                        <Typography.Text type="secondary">{description}</Typography.Text>
                    )}
                    {fileLinks.pdfLink && fileLinks.xmlLink ? (
                        <>
                            <Card
                                style={{ width: 300, marginTop: 16, border: 'none' }}
                                actions={[
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography.Text style={{ color: '#9F9F9F' }}>Decargar PDF</Typography.Text>
                                            <AiOutlineCloudDownload
                                                className="primary"
                                                onClick={() => {
                                                    handleDownloadPdf()
                                                }}
                                                style={{ fontSize: '22px' }}
                                                key="edit"
                                            />
                                        </div>
                                    </>,
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Typography.Text style={{ color: '#9F9F9F' }}>Decargar XML</Typography.Text>
                                            <AiOutlineCloudDownload
                                                className="primary"
                                                onClick={() => {
                                                    handleDownloadXml()
                                                }}
                                                style={{ fontSize: '22px' }}
                                                key="edit"
                                            />
                                        </div>
                                    </>,
                                ]}
                            >
                                <Row justify="center">
                                    <Typography.Title level={5} type="">
                                        Hemos obtenido la factura
                                    </Typography.Title>
                                </Row>
                                <Row justify="center">
                                    <Typography.Text style={{ fontSize: '30px', fontWeight: 900 }}>
                                        {totalString}
                                    </Typography.Text>
                                </Row>
                                <Row justify="center" style={{ marginTop: '10px' }}>
                                    <Typography.Text type="secondary" className="" style={{ textAlign: 'center' }}>
                                        Puedes descargarla haciendo click en los siguentes botones.
                                    </Typography.Text>
                                </Row>
                            </Card>
                        </>
                    ) : (
                        <Form style={{ marginTop: '20px' }} onFinish={handleDownloadInvoice}>
                            <Form.Item name="rfc" rules={[{ required: true, message: 'El RFC es necesario' }]}>
                                <Input
                                    placeholder="RFC"
                                    style={{
                                        textAlign: 'center',
                                        backgroundColor: '#fafbfd',
                                    }}
                                    size="large"
                                />
                            </Form.Item>
                            <Row justify="center">
                                <Button
                                    loading={loading}
                                    style={{
                                        backgroundColor: primaryColor ?? '#8666FF',
                                    }}
                                    htmlType="submit"
                                    type="primary"
                                    size="medium"
                                >
                                    Descargar factura
                                </Button>
                            </Row>
                        </Form>
                    )}
                    <div style={{ marginTop: '12px' }}></div>
                    {fileLinks.pdfLink === null && fileLinks.xmlLink === null && (
                        <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
                            En caso de <strong>no</strong> recordar la información solicítala a {alias}
                        </Typography.Text>
                    )}
                </Space>
            </Col>

            <Col xs={24}>
                <PrivacyBanner />
            </Col>
        </Row>
    )
}

export default ReceiptCompleted
